import { web3Util, request } from '../utils';
import { Response } from '../types';

export const deletePlaylist = async (
  playlistId: string,
): Promise<Response<void>> => {
  const { address, signature, timestamp } = await web3Util.getSignature();
  return request<void>(
    `audioglyphs/wallet/${address}/playlists/${playlistId}/delete`,
    'POST',
    {
      signature,
      timestamp,
    },
  );
};
