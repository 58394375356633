import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import {
  A,
  GridLayout,
  Link,
  P,
  H2,
  AlbumCover,
  Center,
  NetworkActivityIndicator,
  Layout,
  Stack,
} from '../../components';
import { lineHeight, maxBlockContentWidth } from '../../style';
import { Wallet as WalletModel } from '../../types';
import { randomGraph } from '../../randomGraph';
import { useAPIData } from '../../hooks';
import { TAB_ORDER, shortAddress } from '../../utils';
import { Web3Context } from '../../contexts';

const Container = styled.div`
  padding: ${lineHeight}px ${2 * lineHeight}px ${4 * lineHeight}px;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: ${4 * lineHeight}px;
  overflow-y: auto;
  position: relative;
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    flex-direction: column;
  }
`;

export type WalletProps = {
  path: string[];
};

export const Wallet: FC<WalletProps> = ({ path: [, address] }) => {
  const { address: ownAddress } = useContext(Web3Context);

  const [loading, error, data] = useAPIData<WalletModel>(
    `audioglyphs/wallet/${address}`,
  );

  const isOwner = ownAddress === address;

  return (
    <Layout
      title={`Audioglyphs - wallet ${address}`}
      breadcrumbs={
        <>
          <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
            audioglyphs
          </Link>
          {' / '}
          <Link
            plain
            href={`/wallet/${address}`}
            tabIndex={TAB_ORDER.HEADER_BUTTON}
          >
            {shortAddress(address)}
          </Link>
        </>
      }
    >
      <Container>
        {data ? (
          <>
            <div>
              <H2>{isOwner ? 'Your playlists' : 'Playlists'}</H2>
              {data.playlists.length === 0 ? (
                <P>
                  {isOwner
                    ? "You haven't created any playlists yet"
                    : 'None yet'}
                </P>
              ) : (
                <Stack space={lineHeight}>
                  {data.playlists.map(playlist => {
                    return (
                      <P key={playlist.id}>
                        <Link href={`/playlist/${address}/${playlist.id}`}>
                          {playlist.name}
                        </Link>
                      </P>
                    );
                  })}
                </Stack>
              )}
            </div>
            <div style={{ flex: 1 }}>
              <H2>{isOwner ? 'Your collection' : 'Collection'}</H2>
              {data.results.length === 0 ? (
                <P>
                  {isOwner ? "You don't have any Audioglyphs yet" : 'None yet'}
                </P>
              ) : (
                <GridLayout minSize={240}>
                  {data.results.map(glyph => {
                    if (!glyph) return;
                    const graph = randomGraph(glyph.seed);
                    return (
                      <AlbumCover
                        key={graph.seed}
                        metadata={glyph}
                        graph={graph}
                      />
                    );
                  })}
                </GridLayout>
              )}
            </div>
          </>
        ) : (
          <Center>
            <NetworkActivityIndicator
              pending={false}
              active={loading}
              error={!!error}
            />
          </Center>
        )}
      </Container>
    </Layout>
  );
};
