import { createContext } from 'react';
import { AudioGraph } from '../audioGraph';
import { Graph, Metadata } from '../types';

export type AudioGraphOptions = {
  audioGraph: AudioGraph | null;
  graph: Graph | null;
  metadata: Metadata | null;
  play: (graph: Graph, metadata: Metadata) => void;
  pause: () => void;
  stop: () => void;
  playing: boolean;
};

export const AudioGraphContext = createContext<AudioGraphOptions>({
  audioGraph: null,
  graph: null,
  metadata: null,
  play: () => {},
  pause: () => {},
  stop: () => {},
  playing: false,
});
