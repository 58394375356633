import React, { FC } from 'react';
import styled from 'styled-components';
import { Graph } from '../../types';

export const Table = styled.table`
  border-spacing: 0;
  td {
    padding-right: 1em;
    user-select: auto;
  }
`;

export type StatsProps = {
  graph: Graph;
};

export const Stats: FC<StatsProps> = ({ graph }) => {
  const nodes = graph.nodes as any;

  const stats = [
    ['CLOCK', (nodes.CLOCK.audioNode.params.frequency * 15).toFixed(1), 'bpm'],
    ['MOIRE', nodes.PITCH.audioNode.params.frequency.toFixed(1), 'hz'],
    ['VOICE', nodes.OSC.audioNode.params.type],
    ['RANGE', (nodes.RNGE.audioNode.params.gain / 600).toFixed(1), 'oct'],
    [
      'SCALE',
      nodes.QNT.audioNode.params.values.some((v: number) => v % 100 !== 0)
        ? 'equihepatonic'
        : nodes.QNT.audioNode.params.values
            .map((v: number) => v / 100)
            .join(' '),
    ],
    ['SPACE', (nodes.RVB.audioNode.params.reverbTime / 0.9).toFixed(2)],
  ];

  return (
    <Table>
      {stats.map(([label, value, unit]) => (
        <tr>
          <td>{label}</td>
          <td>
            {value} {unit}
          </td>
        </tr>
      ))}
    </Table>
  );
};
