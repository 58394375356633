import React, { FC } from 'react';
import { Dialog, Button, Stack } from '../../components';
import { TAB_ORDER } from '../../utils';

export type ConfirmDeleteDialogProps = {
  close: () => void;
  onConfirm: () => void;
  loading: boolean;
  error: string | null;
};

export const ConfirmDeleteDialog: FC<ConfirmDeleteDialogProps> = ({
  close,
  onConfirm,
  loading,
  error,
}) => {
  return (
    <Dialog
      title="Confirm delete"
      close={close}
      loading={loading}
      error={error}
    >
      <Stack>
        <Button onClick={onConfirm} tabIndex={TAB_ORDER.INPUT}>
          Delete
        </Button>
        <Button onClick={close} tabIndex={TAB_ORDER.INPUT}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};
