import { createGlobalStyle } from 'styled-components';
import { strokeWidth, fontSize, fontWeight, lineHeight, theme } from '../style';

export const GlobalStyle = createGlobalStyle`
  html, body, #app {
    margin: 0;
    padding: 0;
    user-select: none;
    display: block;
    overflow: hidden;
    overscroll-behavior: none;
    width: 100vw;
    height: 100vh;
    font-weight: ${fontWeight};
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
  }

  body {
    ${Object.entries(theme)
      .map(([k, v]) => `${k}:${v};`)
      .join('\n')}

    background-color: var(--background-color);
  }

  * {
    box-sizing: border-box;
    font-family: "Mono", monospace;
    color: var(--text-color);
    vertical-align: top;
  }
  *:focus {
    z-index: 1;
    outline: solid var(--outline-color) ${strokeWidth}px;
    outline-offset: ${strokeWidth}px;
  }
  svg *:focus {
    outline: none;
  }
  ::selection {
    color: var(--text-color);
    background: var(--selection-color);
  }
`;
