import React, { FC, MouseEvent, ReactNode, useContext } from 'react';
import { A } from './A';
import { RoutingContext } from '../contexts';

export type LinkProps = {
  href?: string;
  children: ReactNode;
  className?: string;
  onContextMenu?: (e: MouseEvent<HTMLAnchorElement>) => void;
  tabIndex?: number;
  block?: boolean;
  plain?: boolean;
  onClick?: any;
  target?: string;
};

export const Link: FC<LinkProps> = ({
  href,
  children,
  className,
  onContextMenu,
  tabIndex,
  block,
  plain,
  onClick,
  target,
}) => {
  const { route } = useContext(RoutingContext);

  return (
    <A
      href={href}
      target={target}
      onClick={
        onClick ||
        (e => {
          if (href?.startsWith('http') || href?.startsWith('https')) return;
          if (e.metaKey || e.button !== 0) return;
          e.preventDefault();
          if (href) {
            route(href);
          }
        })
      }
      className={className}
      onContextMenu={onContextMenu}
      tabIndex={tabIndex}
      block={block}
      plain={plain}
    >
      {children}
    </A>
  );
};
