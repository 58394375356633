import React from 'react';
import { H2, P, A } from './';

export default function Mint() {
  return (
    <div style={{ flex: 4 }}>
      <H2 style={{ marginTop: 0 }}>Mint now</H2>

      <P color="white">Sold out</P>
      <P>
        You can find{' '}
        <A href="https://opensea.io/collection/audioglyphs">
          Audioglyphs on OpenSea
        </A>
        .
      </P>
    </div>
  );
}
