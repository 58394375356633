import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { addToPlaylist, createPlaylist } from '../../apiActions';
import { Dialog, ValidatedForm, Button } from '../../components';
import { RoutingContext, Web3Context } from '../../contexts';
import { useAPIData } from '../../hooks';
import { useAPIAction } from '../../hooks/useAPIAction';
import { inputWidth, lineHeight } from '../../style';
import { Wallet } from '../../types';
import { TAB_ORDER } from '../../utils';

export type AddToPlaylistDialogProps = {
  close: () => void;
  id: number;
};

const Playlists = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 ${2 * lineHeight}px;
  display: flex;
  flex-direction: column;
  gap: ${lineHeight}px;
`;

const PlaylistItem = styled.button`
  display: block;
  border: none;
  width: ${inputWidth}px;
  background-color: var(--project-preview-color);
  text-align: left;
  padding: ${0.5 * lineHeight}px ${lineHeight}px;
  &:hover {
    background-color: var(--node-color);
  }
`;

export const AddToPlaylistDialog: FC<AddToPlaylistDialogProps> = ({
  close,
  id: tokenID,
}) => {
  const { route } = useContext(RoutingContext);
  const { address } = useContext(Web3Context);
  const [walletLoading, walletError, wallet] = useAPIData<Wallet>(
    address && `audioglyphs/wallet/${address}`,
    [address],
  );
  const [creatingPlaylist, setCreatingPlaylist] = useState(false);
  const [
    callCreatePlaylist,
    createPlaylistLoading,
    createPlaylistError,
  ] = useAPIAction(createPlaylist);
  const [
    callAddToPlaylist,
    addToPlaylistLoading,
    addToPlaylistError,
  ] = useAPIAction(addToPlaylist);

  return (
    <Dialog
      title="Add to playlist"
      close={close}
      loading={walletLoading || createPlaylistLoading || addToPlaylistLoading}
      error={walletError || createPlaylistError || addToPlaylistError}
    >
      {creatingPlaylist ? (
        <ValidatedForm
          onSubmit={async ({ name }) => {
            const response = await callCreatePlaylist(name, [tokenID]);
            if (response.error) {
              return response.error;
            } else {
              route(`/playlist/${address}/${response.body?.id as string}`);
              return null;
            }
          }}
          fields={{
            name: {
              label: 'Name',
              validate: name => {
                if (name.length < 1) return 'Your playlist needs a name';
                if (name.length > 40) return 'Name is too long';
                return null;
              },
              autoFocus: true,
            },
          }}
          initialValues={{ name: '' }}
          buttonText="Create playlist"
          additionalButtons={
            <Button onClick={() => setCreatingPlaylist(false)}>Cancel</Button>
          }
        />
      ) : (
        wallet && (
          <>
            <Playlists>
              {wallet.playlists.map(({ id: playlistID, name }) => (
                <li key={playlistID}>
                  <Button
                    tabIndex={TAB_ORDER.INPUT}
                    onClick={async () => {
                      const response = await callAddToPlaylist(
                        playlistID,
                        tokenID,
                      );
                      if (response.status === 204) {
                        route(`/playlist/${address}/${playlistID}`);
                      }
                    }}
                  >
                    {name}
                  </Button>
                </li>
              ))}
            </Playlists>
            <Button
              tabIndex={TAB_ORDER.INPUT}
              onClick={() => setCreatingPlaylist(true)}
            >
              New playlist
            </Button>
          </>
        )
      )}
    </Dialog>
  );
};
