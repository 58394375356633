import React, { FC } from 'react';
import styled from 'styled-components';
import { TAB_ORDER } from '../utils';
import { lineHeight } from '../style';

const SVG = styled.svg`
  display: block;
  cursor: pointer;
  rect {
    fill: var(--play-button-color);
    &:hover {
      fill: var(--play-button-hover-color);
    }
  }
`;

export type StopButtonProps = {
  stop: () => void;
  size?: number;
};

export const StopButton: FC<StopButtonProps> = ({
  stop,
  size = lineHeight,
}) => {
  return (
    <SVG
      tabIndex={TAB_ORDER.HEADER_BUTTON}
      onClick={() => stop()}
      onKeyDown={e => {
        if (e.key === 'Enter') stop();
      }}
      width={size}
      height={size}
      viewBox="0 0 1 1"
    >
      <title>stop</title>
      <rect x={0.05} y={0.05} width={0.9} height={0.9} />
    </SVG>
  );
};
