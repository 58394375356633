import React, { FC, useRef, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Graph, IOType, add } from '../../types';
import { cornerRadius, inputWidth, theme } from '../../style';
import {
  graphBounds,
  getDefaultNodeSize,
  getRawIOCenterPosition,
} from '../../utils';
import { AudioGraphContext } from '../../contexts';
import { TriggerEvent } from '../../audioNodeUtils';

const SVG = styled.svg`
  margin: 0;
`;
const Node = styled.rect`
  fill: var(--visualization-color);
  stroke: none;
  stroke-width: 0.7;
`;
const NonScalingEdge = styled.line`
  stroke: var(--visualization-color);
  stroke-linecap: round;
  stroke-width: 0.7;
`;

export type GraphThumbnailProps = {
  graph: Graph;
  width?: number;
};

export const GraphThumbnail: FC<GraphThumbnailProps> = ({
  graph,
  width = inputWidth,
}) => {
  const [[x, y], [w, h]] = graphBounds(graph);
  const height = (h / w) * width;
  const { audioGraph } = useContext(AudioGraphContext);
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (audioGraph?.graph.seed === graph.seed) {
      const listeners = Object.entries(audioGraph.audioNodes).reduce(
        (memo, [id, node]) => {
          memo[id] = ((e: TriggerEvent) => {
            if (e.detail) {
              (svgRef.current?.querySelector(
                `[data-id=${id}]`,
              ) as any)?.beginElement();
            }
          }) as EventListener;
          node.addEventListener('trigger', memo[id]);
          return memo;
        },
        {} as { [id: string]: EventListener },
      );

      return () => {
        Object.entries(listeners).forEach(([id, listener]) =>
          audioGraph?.audioNodes[id].removeEventListener('trigger', listener),
        );
      };
    }
    return undefined;
  }, [audioGraph, graph]);

  return (
    <SVG
      ref={svgRef}
      viewBox={`${x - 0.35} ${y - 0.35} ${w + 0.7} ${h + 0.7}`}
      width={width}
      height={height}
    >
      {Object.entries(graph.edges).map(([id, { from, to }]) => {
        const {
          audioNode: { type: fromNodeType },
          data: { position: fromNodePosition },
        } = graph.nodes[from.node];
        const {
          audioNode: { type: toNodeType },
          data: { position: toNodePosition },
        } = graph.nodes[to.node];

        const [x1, y1] = add(
          fromNodePosition,
          getRawIOCenterPosition(fromNodeType, IOType.Output, from.index),
        );
        const [x2, y2] = add(
          toNodePosition,
          getRawIOCenterPosition(toNodeType, IOType.Input, to.index),
        );
        return <NonScalingEdge key={id} x1={x1} y1={y1} x2={x2} y2={y2} />;
      })}
      {Object.entries(graph.nodes).map(([id, node]) => {
        const [x, y] = node.data.position;
        const [w, h] = getDefaultNodeSize(node.audioNode.type);
        return (
          <Node key={id} x={x} y={y} width={w} rx={cornerRadius} height={h}>
            <animate
              data-id={id}
              attributeName="fill"
              begin="indefinate"
              dur="0.15s"
              from={theme['--visualization-secondary-color']}
              to={theme['--background-color']}
            />
          </Node>
        );
      })}
    </SVG>
  );
};
