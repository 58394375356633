import { web3Util, request } from '../utils';
import { Response } from '../types';

export const createPlaylist = async (
  name: string,
  glyphs: number[],
): Promise<Response<{ id: string }>> => {
  const { address, signature, timestamp } = await web3Util.getSignature();
  return request<{ id: string }>(
    `audioglyphs/wallet/${address}/playlists`,
    'POST',
    {
      signature,
      timestamp,
      name,
      glyphs,
    },
  );
};
