import { GraphForStorage, AudioNodeType } from './types';
import { graphFromStorage, setNamesAsIDs } from './utils';

export const baseGraphForStorage: GraphForStorage = {
  seed: 0,
  name: '',
  nodes: {
    NuHYLvDj6Idq: {
      id: 'NuHYLvDj6Idq',
      audioNode: {
        type: AudioNodeType.SampleAndHoldNode,
        params: { clock: 0, signal: 0 },
      },
      data: { position: [50, 49], name: 'SAH' },
    },
    Owj7X9ZeqQqs: {
      id: 'Owj7X9ZeqQqs',
      audioNode: {
        type: AudioNodeType.OscillatorNode,
        params: { frequency: 8.7, detune: 0, type: 'sine' },
      },
      data: { position: [46, 52], name: 'PITCH' },
    },
    TFM3Apkzutau: {
      id: 'TFM3Apkzutau',
      audioNode: {
        type: AudioNodeType.OscillatorNode,
        params: { frequency: 8, detune: 0, type: 'square' },
      },
      data: { position: [43, 38], name: 'CLOCK' },
    },
    tiPpEIoua8sN: {
      id: 'tiPpEIoua8sN',
      audioNode: { type: AudioNodeType.GainNode, params: { gain: 2400 } },
      data: { position: [54, 49], name: 'RNGE' },
    },
    NQlC0sYXoLH5: {
      id: 'NQlC0sYXoLH5',
      audioNode: {
        type: AudioNodeType.OscillatorNode,
        params: { frequency: 339, detune: 0, type: 'sawtooth' },
      },
      data: { position: [62, 46], name: 'OSC' },
    },
    MS8EnwELxtqW: {
      id: 'MS8EnwELxtqW',
      audioNode: { type: AudioNodeType.AudioDestinationNode, params: {} },
      data: { position: [82, 38], name: 'DEST' },
    },
    Zaoi4Cwj94jk: {
      id: 'Zaoi4Cwj94jk',
      audioNode: { type: AudioNodeType.GainNode, params: { gain: 0 } },
      data: { position: [66, 49], name: 'VCA' },
    },
    H0iZVktJHOKP: {
      id: 'H0iZVktJHOKP',
      audioNode: {
        type: AudioNodeType.OscillatorNode,
        params: { frequency: 0.05, detune: 0, type: 'sawtooth' },
      },
      data: { position: [38, 52], name: 'PITCH_MOD' },
    },
    TAAZVGI972JD: {
      id: 'TAAZVGI972JD',
      audioNode: { type: AudioNodeType.GainNode, params: { gain: 0 } },
      data: { position: [42, 52], name: 'PITCH_MOD_AMT' },
    },
    ncYiNHAkFmt0: {
      id: 'ncYiNHAkFmt0',
      audioNode: {
        type: AudioNodeType.ADEnvelopeNode,
        params: { clock: 0, attack: 0.01, decay: 0.3, hold: false },
      },
      data: { position: [62, 52], name: 'AD' },
    },
    jRvPUraIs3E2: {
      id: 'jRvPUraIs3E2',
      audioNode: {
        type: AudioNodeType.ReverbNode,
        params: {
          diffusion: 0.6,
          lp: 0.99,
          reverbTime: 0.5,
          amount: 0.5,
          gain: 0.4,
        },
      },
      data: { position: [82, 46], name: 'RVB' },
    },
    afCzBq16AIc9: {
      id: 'afCzBq16AIc9',
      audioNode: {
        type: AudioNodeType.BiquadFilterNode,
        params: { frequency: 880, detune: 0, Q: 4, gain: 0, type: 'lowpass' },
      },
      data: { position: [70, 48], name: 'FLT' },
    },
    '3ERbiRcXnGn5': {
      id: '3ERbiRcXnGn5',
      audioNode: {
        type: AudioNodeType.OscillatorNode,
        params: { frequency: 0.05, detune: 0, type: 'sine' },
      },
      data: { position: [62, 57], name: 'FLT_LFO' },
    },
    '76GA7wK6Y2Le': {
      id: '76GA7wK6Y2Le',
      audioNode: { type: AudioNodeType.GainNode, params: { gain: 700 } },
      data: { position: [67, 55], name: 'FLT_LFO_AMT' },
    },
    k5qlpGpaBPwK: {
      id: 'k5qlpGpaBPwK',
      audioNode: {
        type: AudioNodeType.RhythmNode,
        params: {
          clock: 0,
          reset: 0,
          sequence: [1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0],
        },
      },
      data: { position: [53, 40], name: 'BD_RTM' },
    },
    '9fZ8fd5VzN9e': {
      id: '9fZ8fd5VzN9e',
      audioNode: {
        type: AudioNodeType.TriggeredSamplerNode,
        params: {
          buffer: '/AOP4EJ5R3BZ7RvEKnJu8Ag.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [58, 40], name: 'BD_SMP' },
    },
    iUB0Lm61FaGm: {
      id: 'iUB0Lm61FaGm',
      audioNode: { type: AudioNodeType.GainNode, params: { gain: 0 } },
      data: { position: [63, 40], name: 'BD_GAN' },
    },
    cSx9gqRfuQEO: {
      id: 'cSx9gqRfuQEO',
      audioNode: {
        type: AudioNodeType.RhythmNode,
        params: { clock: 0, reset: 0, sequence: [0, 0, 1, 0] },
      },
      data: { position: [52, 31], name: 'HH_RTM' },
    },
    buxHPSiQd1Uj: {
      id: 'buxHPSiQd1Uj',
      audioNode: {
        type: AudioNodeType.TriggeredSamplerNode,
        params: {
          buffer: '/NyFXbZ66NsZbRCQDwX1f-Q.wav',
          clock: 0,
          detune: 120,
        },
      },
      data: { position: [59, 30], name: 'HH_SMP' },
    },
    hUB0Lm61FaGl: {
      id: 'hUB0Lm61FaGl',
      audioNode: { type: AudioNodeType.GainNode, params: { gain: 0 } },
      data: { position: [69, 31], name: 'HH_VCA' },
    },
    '3HPgCO37IuQK': {
      id: '3HPgCO37IuQK',
      audioNode: {
        type: AudioNodeType.BiquadFilterNode,
        params: { frequency: 6040, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [64, 29], name: 'HH_FLT' },
    },
    wgN6A7EVT7Ve: {
      id: 'wgN6A7EVT7Ve',
      audioNode: {
        type: AudioNodeType.RhythmNode,
        params: { clock: 0, reset: 0, sequence: [0, 0, 0, 0, 0, 1, 0, 0] },
      },
      data: { position: [50, 23], name: 'SNR_RTM' },
    },
    GsTTVXBDw8kA: {
      id: 'GsTTVXBDw8kA',
      audioNode: {
        type: AudioNodeType.TriggeredSamplerNode,
        params: {
          buffer: '/fIt-hst_gzdF2tR4BIfC8g.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [57, 22], name: 'SNR_SMP' },
    },
    fQqn9fksAqlI: {
      id: 'fQqn9fksAqlI',
      audioNode: {
        type: AudioNodeType.BiquadFilterNode,
        params: { frequency: 220, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [62, 22], name: 'SNR_FLT' },
    },
    oLDTSHsMrarn: {
      id: 'oLDTSHsMrarn',
      audioNode: { type: AudioNodeType.GainNode, params: { gain: 0 } },
      data: { position: [67, 23], name: 'SNR_GAN' },
    },
    r7JXnAGNWY2g: {
      id: 'r7JXnAGNWY2g',
      audioNode: {
        type: AudioNodeType.SequenceNode,
        params: {
          clock: 0,
          reset: 0,
          sequence: [0.08, 0.09, 0.07, 0.06, 0.07],
        },
      },
      data: { position: [64, 36], name: 'HH_LVL' },
    },
    '43VRdnWuwAH4': {
      id: '43VRdnWuwAH4',
      audioNode: {
        type: AudioNodeType.QuantizerNode,
        params: { signal: 0, values: [0, 200, 300, 700, 1000], range: 1200 },
      },
      data: { position: [58, 49], name: 'QNT' },
    },
    ZuD7rlE6ewyB: {
      id: 'ZuD7rlE6ewyB',
      audioNode: { type: AudioNodeType.ChannelMergerNode, params: {} },
      data: { position: [74, 48], name: 'CHM' },
    },
    ngwh0RIAXuRj: {
      id: 'ngwh0RIAXuRj',
      audioNode: { type: AudioNodeType.GainNode, params: { gain: 0.3 } },
      data: { position: [78, 49], name: 'GAN' },
    },
  },
  edges: {
    Vy6Edpyr1jk2: {
      id: 'Vy6Edpyr1jk2',
      from: { type: 1, node: 'Owj7X9ZeqQqs', index: 0 },
      to: { type: 0, node: 'NuHYLvDj6Idq', index: 1 },
    },
    QLD2bA2PfeKL: {
      id: 'QLD2bA2PfeKL',
      from: { type: 1, node: 'TFM3Apkzutau', index: 0 },
      to: { type: 0, node: 'NuHYLvDj6Idq', index: 0 },
    },
    LHTpyj0kZXBS: {
      id: 'LHTpyj0kZXBS',
      from: { type: 1, node: 'NuHYLvDj6Idq', index: 0 },
      to: { type: 0, node: 'tiPpEIoua8sN', index: 0 },
    },
    puMbC0vzlY7M: {
      id: 'puMbC0vzlY7M',
      from: { type: 1, node: 'H0iZVktJHOKP', index: 0 },
      to: { type: 0, node: 'TAAZVGI972JD', index: 0 },
    },
    '5IFAfuYSgmFp': {
      id: '5IFAfuYSgmFp',
      from: { type: 1, node: 'TAAZVGI972JD', index: 0 },
      to: { type: 0, node: 'Owj7X9ZeqQqs', index: 0 },
    },
    xU1COuEXuthQ: {
      id: 'xU1COuEXuthQ',
      from: { type: 1, node: 'NQlC0sYXoLH5', index: 0 },
      to: { type: 0, node: 'Zaoi4Cwj94jk', index: 0 },
    },
    KW7ptSWb9NRl: {
      id: 'KW7ptSWb9NRl',
      from: { type: 1, node: 'jRvPUraIs3E2', index: 0 },
      to: { type: 0, node: 'MS8EnwELxtqW', index: 0 },
    },
    b4JQ4P93YOEY: {
      id: 'b4JQ4P93YOEY',
      from: { type: 1, node: 'ncYiNHAkFmt0', index: 0 },
      to: { type: 0, node: 'Zaoi4Cwj94jk', index: 1 },
    },
    QKPaOfTHEsXz: {
      id: 'QKPaOfTHEsXz',
      from: { type: 1, node: 'Zaoi4Cwj94jk', index: 0 },
      to: { type: 0, node: 'afCzBq16AIc9', index: 0 },
    },
    r4eML1Y0C9Ra: {
      id: 'r4eML1Y0C9Ra',
      from: { type: 1, node: '76GA7wK6Y2Le', index: 0 },
      to: { type: 0, node: 'afCzBq16AIc9', index: 2 },
    },
    '6XAiBau88Gju': {
      id: '6XAiBau88Gju',
      from: { type: 1, node: 'k5qlpGpaBPwK', index: 0 },
      to: { type: 0, node: '9fZ8fd5VzN9e', index: 0 },
    },
    '9njHYknF69W4': {
      id: '9njHYknF69W4',
      from: { type: 1, node: 'TFM3Apkzutau', index: 0 },
      to: { type: 0, node: 'k5qlpGpaBPwK', index: 0 },
    },
    cSx9gqRfuQEO: {
      id: 'cSx9gqRfuQEO',
      from: { type: 1, node: 'cSx9gqRfuQEO', index: 0 },
      to: { type: 0, node: 'buxHPSiQd1Uj', index: 0 },
    },
    Yl5mMWpfEtlX: {
      id: 'Yl5mMWpfEtlX',
      from: { type: 1, node: 'TFM3Apkzutau', index: 0 },
      to: { type: 0, node: 'cSx9gqRfuQEO', index: 0 },
    },
    uf4qrfDjSB81: {
      id: 'uf4qrfDjSB81',
      from: { type: 1, node: 'buxHPSiQd1Uj', index: 0 },
      to: { type: 0, node: '3HPgCO37IuQK', index: 0 },
    },
    zbYEKoeiMr6J: {
      id: 'zbYEKoeiMr6J',
      from: { type: 1, node: '3HPgCO37IuQK', index: 0 },
      to: { type: 0, node: 'hUB0Lm61FaGl', index: 0 },
    },
    wgN6A7EVT7Ve: {
      id: 'wgN6A7EVT7Ve',
      from: { type: 1, node: 'fQqn9fksAqlI', index: 0 },
      to: { type: 0, node: 'oLDTSHsMrarn', index: 0 },
    },
    rr5aDoA7gC58: {
      id: 'rr5aDoA7gC58',
      from: { type: 1, node: 'GsTTVXBDw8kA', index: 0 },
      to: { type: 0, node: 'fQqn9fksAqlI', index: 0 },
    },
    ZVUo0iod6CVH: {
      id: 'ZVUo0iod6CVH',
      from: { type: 1, node: 'wgN6A7EVT7Ve', index: 0 },
      to: { type: 0, node: 'GsTTVXBDw8kA', index: 0 },
    },
    gERWR6wj3zes: {
      id: 'gERWR6wj3zes',
      from: { type: 1, node: 'TFM3Apkzutau', index: 0 },
      to: { type: 0, node: 'wgN6A7EVT7Ve', index: 0 },
    },
    EL8Tn3h9ckFO: {
      id: 'EL8Tn3h9ckFO',
      from: { type: 1, node: 'cSx9gqRfuQEO', index: 0 },
      to: { type: 0, node: 'r7JXnAGNWY2g', index: 0 },
    },
    gt1Q5Of1Kqpq: {
      id: 'gt1Q5Of1Kqpq',
      from: { type: 1, node: 'r7JXnAGNWY2g', index: 0 },
      to: { type: 0, node: 'hUB0Lm61FaGl', index: 1 },
    },
    H05EQDJT2O2A: {
      id: 'H05EQDJT2O2A',
      from: { type: 1, node: 'tiPpEIoua8sN', index: 0 },
      to: { type: 0, node: '43VRdnWuwAH4', index: 0 },
    },
    kDCNPqPvKS1w: {
      id: 'kDCNPqPvKS1w',
      from: { type: 1, node: '43VRdnWuwAH4', index: 0 },
      to: { type: 0, node: 'NQlC0sYXoLH5', index: 1 },
    },
    OZG5pKt0aqFh: {
      id: 'OZG5pKt0aqFh',
      from: { type: 1, node: '43VRdnWuwAH4', index: 1 },
      to: { type: 0, node: 'ncYiNHAkFmt0', index: 0 },
    },
    gDvlpxxnG63I: {
      id: 'gDvlpxxnG63I',
      from: { type: 1, node: 'afCzBq16AIc9', index: 0 },
      to: { type: 0, node: 'ZuD7rlE6ewyB', index: 0 },
    },
    rARIfSkLjgdr: {
      id: 'rARIfSkLjgdr',
      from: { type: 1, node: '3ERbiRcXnGn5', index: 0 },
      to: { type: 0, node: '76GA7wK6Y2Le', index: 0 },
    },
    bBGEh1rTPOg4: {
      id: 'bBGEh1rTPOg4',
      from: { type: 1, node: 'ZuD7rlE6ewyB', index: 0 },
      to: { type: 0, node: 'ngwh0RIAXuRj', index: 0 },
    },
    AhzmSfRAwcZk: {
      id: 'AhzmSfRAwcZk',
      from: { type: 1, node: 'ngwh0RIAXuRj', index: 0 },
      to: { type: 0, node: 'jRvPUraIs3E2', index: 0 },
    },
    TP85FQyeqwzu: {
      id: 'TP85FQyeqwzu',
      from: { type: 1, node: 'ncYiNHAkFmt0', index: 0 },
      to: { type: 0, node: '76GA7wK6Y2Le', index: 0 },
    },
    U0QqLQXg9Dfn: {
      id: 'U0QqLQXg9Dfn',
      from: { type: 1, node: '9fZ8fd5VzN9e', index: 0 },
      to: { type: 0, node: 'iUB0Lm61FaGm', index: 0 },
    },
    V1QqLQXg9Dfgm: {
      id: 'V1QqLQXg9Dgm',
      from: { type: 1, node: 'iUB0Lm61FaGm', index: 0 },
      to: { type: 0, node: 'MS8EnwELxtqW', index: 0 },
    },
    VKixb3ZRgDjl: {
      id: 'VKixb3ZRgDjl',
      from: { type: 1, node: 'oLDTSHsMrarn', index: 0 },
      to: { type: 0, node: 'MS8EnwELxtqW', index: 0 },
    },
    xlh9X8pPls3T: {
      id: 'xlh9X8pPls3T',
      from: { type: 1, node: 'hUB0Lm61FaGl', index: 0 },
      to: { type: 0, node: 'ZuD7rlE6ewyB', index: 0 },
    },
    OHcBlTVyt2sk: {
      id: 'OHcBlTVyt2sk',
      from: { type: 1, node: 'hUB0Lm61FaGl', index: 0 },
      to: { type: 0, node: 'ZuD7rlE6ewyB', index: 1 },
    },
    '9qzEDXlVzYEH': {
      id: '9qzEDXlVzYEH',
      from: { type: 1, node: 'afCzBq16AIc9', index: 0 },
      to: { type: 0, node: 'ZuD7rlE6ewyB', index: 1 },
    },
  },
};

export const baseGraph = graphFromStorage(setNamesAsIDs(baseGraphForStorage));
