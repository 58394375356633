import React, { FC, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AudioGraphContext } from '../contexts';
import { lineHeight } from '../style';
import { PlayButton } from './PlayButton';
import { StopButton } from './StopButton';
import { CurrentTime } from './CurrentTime';
import { Link } from './Link';

const Container = styled.div`
  position: fixed;
  bottom: ${lineHeight}px;
  padding: ${lineHeight}px ${lineHeight * 2}px;
  display: flex;
  gap: 1em;
  align-items: center;
  background-color: var(--background-color);
  z-index: 999;
`;

export type PlayerControlsProps = {};

export const PlayerControls: FC<PlayerControlsProps> = () => {
  const {
    audioGraph,
    graph,
    metadata,
    play,
    pause,
    stop,
    playing,
  } = useContext(AudioGraphContext);
  const [shown, setShown] = useState(!!audioGraph);

  useEffect(() => {
    if (audioGraph && !shown) setShown(true);
  }, [audioGraph]);

  return audioGraph && graph && metadata ? (
    <Container>
      <PlayButton
        playing={playing}
        toggle={playing ? pause : () => play(graph, metadata)}
      />
      <StopButton stop={stop} />
      <CurrentTime audioGraph={audioGraph} />
      <Link href={`/gallery/${metadata.tokenId}`}>{graph.name}</Link>
    </Container>
  ) : null;
};
