import React, { FC, useMemo, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { randomGraph } from '../../randomGraph';
import {
  Layout,
  Link,
  Stack,
  Button,
  AlbumCover,
  NetworkActivityIndicator,
  Center,
  A,
} from '../../components';
import { TAB_ORDER } from '../../utils';
import { lineHeight, maxBlockContentWidth } from '../../style';
import { Stats } from './Stats';
import { Positions } from './Positions';
import { DownloadAudioFileDialog } from './DownloadAudioFileDialog';
import { EmbedDialog } from './EmbedDialog';
import { AddToPlaylistDialog } from './AddToPlaylistDialog';
import { GraphThumbnail } from './GraphThumbnail';
import { AudioGraphContext, RoutingContext } from '../../contexts';
import { useAPIData } from '../../hooks';
import { Metadata } from '../../types';
import AUDIOGLYPH_CONTRACT_ADDRESS from '../../data/AUDIOGLYPH_CONTRACT_ADDRESS';

export type PlayerProps = {
  path: string[];
};

const Container = styled.div`
  padding: ${lineHeight}px ${2 * lineHeight}px;
  display: flex;
  flex-flow: row-wrap;
  gap: ${lineHeight}px;
  position: relative;
  overflow-y: auto;
`;

const Col = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
`;

const LeftCol = styled(Col)`
  flex: 1;
  max-width: 420px;
`;

const RightCol = styled(Col)`
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    display: none;
  }
`;

const ErrorText = styled.div`
  color: var(--error-text-color);
`;

const Actions = styled.div`
  padding: ${2 * lineHeight}px 0;
`;

const Links = styled.div`
  padding: 0 0 ${4 * lineHeight}px;
  display: flex;
  flex-direction: column;
  gap: ${lineHeight}px;
`;

export const Player: FC<PlayerProps> = ({ path }) => {
  const tokenID = parseInt(path[1]);
  const [loading, error, metadata] = useAPIData<Metadata>(
    Number.isNaN(tokenID) ? undefined : `audioglyphs/metadata/${tokenID}`,
    [tokenID],
  );
  const graph = useMemo(() => metadata && randomGraph(metadata.seed), [
    metadata,
  ]);
  const { route } = useContext(RoutingContext);
  const { audioGraph, play, pause, playing } = useContext(AudioGraphContext);
  const [
    downloadAudioFileDialogActive,
    setDownloadAudioFileDialogActive,
  ] = useState(false);
  const [embedDialogActive, setEmbedDialogActive] = useState(false);
  const [addToPlaylistDialogActive, setAddToPlaylistDialogActive] = useState(
    false,
  );

  const graphIsPlaying = playing && audioGraph?.graph.seed === graph?.seed;

  useEffect(() => {
    // this uses keydown and prevents default to override global play/pause
    // defined in root component and listening to keypress
    const onKeydown = (e: KeyboardEvent) => {
      if (
        e.key === ' ' &&
        graph &&
        metadata &&
        (e.target as HTMLElement).tagName !== 'INPUT'
      ) {
        e.stopPropagation();
        e.preventDefault();
        if (graphIsPlaying) {
          pause();
        } else {
          play(graph, metadata);
        }
      }
    };
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, [play, pause, graph, metadata, graphIsPlaying]);

  useEffect(() => {
    // start playing this graph if something is playing already
    if (graph && metadata && playing && !graphIsPlaying) {
      play(graph, metadata);
    }
  }, [graph, metadata, graphIsPlaying]);

  return (
    <>
      {graph && addToPlaylistDialogActive && (
        <AddToPlaylistDialog
          id={tokenID}
          close={() => setAddToPlaylistDialogActive(false)}
        />
      )}
      {graph && downloadAudioFileDialogActive && (
        <DownloadAudioFileDialog
          graph={graph}
          close={() => setDownloadAudioFileDialogActive(false)}
        />
      )}
      {graph && embedDialogActive && (
        <EmbedDialog id={tokenID} close={() => setEmbedDialogActive(false)} />
      )}
      <Layout
        title={graph ? `Audioglyphs - ${graph.name}` : 'Audioglyphs'}
        breadcrumbs={
          <>
            <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
              audioglyphs
            </Link>
            {' / '}
            <Link plain href="/gallery" tabIndex={TAB_ORDER.HEADER_BUTTON}>
              gallery
            </Link>
            {' / '}
            <Link
              plain
              href={`/gallery/${tokenID}`}
              tabIndex={TAB_ORDER.HEADER_BUTTON}
            >
              {graph?.name}
            </Link>
          </>
        }
      >
        {graph && metadata ? (
          <Container>
            {error ? (
              <Col>
                <ErrorText>Invalid glyph!</ErrorText>
              </Col>
            ) : (
              <>
                <LeftCol>
                  <AlbumCover graph={graph} metadata={metadata} />
                  <Actions>
                    <Stack>
                      <Button
                        onClick={() => setAddToPlaylistDialogActive(true)}
                      >
                        Add to playlist
                      </Button>
                      <Button
                        onClick={() => setDownloadAudioFileDialogActive(true)}
                      >
                        Download
                      </Button>
                      <Button onClick={() => setEmbedDialogActive(true)}>
                        Embded
                      </Button>
                    </Stack>
                  </Actions>
                  <Links>
                    <A
                      href={`https://opensea.io/assets/${AUDIOGLYPH_CONTRACT_ADDRESS}/${tokenID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View on OpenSea
                    </A>
                    <Link href={`/gallery/${tokenID}/stems`}>Stems</Link>
                  </Links>
                </LeftCol>
                <RightCol>
                  <Stack space={lineHeight}>
                    <GraphThumbnail graph={graph} width={100} />
                    <Positions graph={graph} />
                    <Stats graph={graph} />
                    <Button
                      disabled={tokenID <= 0}
                      onClick={
                        tokenID > 0
                          ? () => route(`/gallery/${tokenID - 1}`)
                          : undefined
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      disabled={tokenID >= 10000}
                      onClick={
                        tokenID < 10000
                          ? () => route(`/gallery/${tokenID + 1}`)
                          : undefined
                      }
                    >
                      Next
                    </Button>
                  </Stack>
                </RightCol>
              </>
            )}
          </Container>
        ) : (
          <Center>
            <NetworkActivityIndicator
              pending={false}
              active={loading}
              error={!!error}
            />
          </Center>
        )}
      </Layout>
    </>
  );
};
