import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { RhythmNode } from '../../audioNodes';
import { AudioGraphContext } from '../../contexts';
import { Graph, QuantizerNodeParams, RhythmNodeParams } from '../../types';

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const SVG = styled.svg`
  background: var(--grid-color);
`;

export const Rect = styled.rect`
  fill: var(--background-secondary-color);
`;

export const Circle = styled.circle`
  fill: var(
    ${({ on }: { on: boolean }) =>
      on ? '--visualization-secondary-color' : '--visualization-color'}
  );
`;

export const Dummy = styled.div`
  width: 10px;
  height: 10px;
  background: var(--grid-color);
`;

type SequenceProps = {
  graph: Graph;
  nodeID: string;
};

const Rhythm: FC<SequenceProps> = ({ nodeID, graph }) => {
  const [position, setPosition] = useState(0);
  const { audioGraph, playing } = useContext(AudioGraphContext);

  useEffect(() => {
    if (audioGraph) {
      const node = audioGraph.audioNodes[nodeID] as RhythmNode;
      const listener = () => setPosition(node.position);
      node.addEventListener('trigger', listener);
      return () => node.removeEventListener('trigger', listener);
    }
    return undefined;
  }, [nodeID, audioGraph]);

  const graphIsPlaying = playing && audioGraph?.graph.seed === graph.seed;
  const values = (graph.nodes[nodeID].audioNode.params as RhythmNodeParams)
    .sequence;

  return (
    <SVG viewBox={`0 0 ${values.length} 1`} height={10}>
      {graphIsPlaying && <Rect x={position} y={0} width={1} height={1} />}
      {values.map(
        (on, i) =>
          on === 1 && (
            <Circle
              key={i}
              cx={i + 0.5}
              cy={0.5}
              r={0.25}
              on={graphIsPlaying && i === position}
            />
          ),
      )}
    </SVG>
  );
};

const Scale: FC<SequenceProps> = ({ nodeID, graph }) => {
  const [position, setPosition] = useState(0);
  const { audioGraph, playing } = useContext(AudioGraphContext);

  useEffect(() => {
    if (audioGraph) {
      const node = audioGraph.audioNodes[nodeID] as RhythmNode;
      const listener = () => setPosition(node.position);
      node.addEventListener('trigger', listener);
      return () => node.removeEventListener('trigger', listener);
    }
    return undefined;
  }, [nodeID, audioGraph]);

  const graphIsPlaying = playing && audioGraph?.graph.seed === graph.seed;
  const values = (graph.nodes[nodeID].audioNode
    .params as QuantizerNodeParams).values.map((v: number) => v / 100);

  return (
    <SVG viewBox={`0 0 12 1`} height={10}>
      {graphIsPlaying && (
        <Rect x={values[position]} y={0} width={1} height={1} />
      )}
      {values.map((v, i) => (
        <Circle
          key={i}
          cx={v + 0.5}
          cy={0.5}
          r={0.25}
          on={graphIsPlaying && i === position}
        />
      ))}
    </SVG>
  );
};

export type PositionsProps = {
  graph: Graph;
};

export const Positions: FC<PositionsProps> = ({ graph }) => {
  const nodes = graph.nodes as any;

  return (
    <Col>
      <Scale graph={graph} nodeID="QNT" />
      {nodes.BD_RTM.audioNode.params.sequence.some((v: number) => v) ? (
        <Rhythm graph={graph} nodeID="BD_RTM" />
      ) : (
        <Dummy />
      )}
      {nodes.SNR_RTM.audioNode.params.sequence.some((v: number) => v) ? (
        <Rhythm graph={graph} nodeID="SNR_RTM" />
      ) : (
        <Dummy />
      )}
      {nodes.HH_RTM.audioNode.params.sequence.some((v: number) => v) ? (
        <Rhythm graph={graph} nodeID="HH_RTM" />
      ) : (
        <Dummy />
      )}
    </Col>
  );
};
