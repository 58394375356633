import React, { FC } from 'react';
import styled from 'styled-components';
import { lineHeight } from '../style';

export type PlayButtonProps = {
  playing: boolean;
  toggle?: () => void;
  size?: number;
  disabled?: boolean;
};

const SVG = styled.svg`
  rect,
  path {
    fill: var(
      ${({ disabled }: { disabled?: boolean }) =>
        disabled ? '--input-disabled-text-color' : '--play-button-color'}
    );
  }
  &:hover rect,
  &:hover path {
    fill: var(
      ${({ disabled }: { disabled?: boolean }) =>
        disabled ? '--input-disabled-text-color' : '--play-button-hover-color'}
    );
  }
`;

export const PlayButton: FC<PlayButtonProps> = ({
  size = lineHeight,
  playing,
  toggle,
  disabled,
}) => (
  <SVG
    tabIndex={disabled ? undefined : 1}
    onClick={toggle}
    onKeyDown={
      toggle &&
      (e => {
        if (e.key === 'Enter') toggle();
      })
    }
    width={size}
    height={size}
    viewBox="0 0 1 1"
    disabled={disabled}
  >
    <title>{playing ? 'pause' : 'play'}</title>
    {playing ? (
      <>
        <rect x={0} y={0.05} width={0.35} height={0.9} />
        <rect x={0.65} y={0.05} width={0.35} height={0.9} />
      </>
    ) : (
      <path d="m 0 0 L 1 0.5 L 0 1 L 0 0" />
    )}
  </SVG>
);
