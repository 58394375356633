import { TriggerEvent } from '../audioNodeUtils';

export class QuantizerNode extends AudioWorkletNode {
  readonly signal: AudioParam;
  private _values: number[];
  private _range: number;
  private _position: number = 0;

  constructor(
    context: BaseAudioContext,
    { values = [0], range = 100 }: { values: number[]; range: number },
  ) {
    super(context, 'QuantizerProcessor', {
      numberOfInputs: 0,
      numberOfOutputs: 2,
      outputChannelCount: [1, 1],
      channelCount: 1,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
      processorOptions: { values, range },
    });
    this.port.onmessage = ({ data }) => {
      this._position = data;
      this.dispatchEvent(new TriggerEvent());
    };

    this._values = values;
    this._range = range;

    // @ts-ignore
    this.signal = this.parameters.get('signal');
  }

  get position(): number {
    return this._position;
  }
  get values(): number[] {
    return this._values;
  }
  set values(values: number[]) {
    this._values = values;
    this.port.postMessage({ values: new Float32Array(values) });
  }
  get range(): number {
    return this._range;
  }
  set range(range: number) {
    this._range = range;
    this.port.postMessage({ range });
  }
}
