import { TriggerEvent } from '../audioNodeUtils';

export class SequenceNode extends AudioWorkletNode {
  readonly clock: AudioParam;
  readonly reset: AudioParam;
  private _sequence: number[];
  private _position: number = 0;

  constructor(
    context: BaseAudioContext,
    { sequence = [] }: { sequence: number[] },
  ) {
    super(context, 'SequenceProcessor', {
      numberOfInputs: 0,
      numberOfOutputs: 1,
      outputChannelCount: [1],
      channelCount: 1,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
      processorOptions: { sequence },
    });
    this.port.onmessage = ({ data }) => {
      this._position = data;
      this.dispatchEvent(new TriggerEvent());
    };

    this._sequence = sequence;

    // @ts-ignore
    this.clock = this.parameters.get('clock');
    // @ts-ignore
    this.reset = this.parameters.get('reset');
  }

  get position(): number {
    return this._position;
  }
  get sequence(): number[] {
    return this._sequence;
  }
  set sequence(sequence: number[]) {
    this._sequence = sequence;
    this._position =
      sequence.length === 0 ? 0 : this._position % sequence.length;
    this.port.postMessage(new Float32Array(sequence));
  }
}
