import React from 'react';

export const useLocalState = (defaultValue: any, key: string) => {
  const [value, localUpdate] = React.useState(
    localStorage?.getItem(key) ?? defaultValue,
  );

  function update(newValue: any) {
    localStorage?.setItem(
      key,
      typeof newValue === 'function' ? newValue(value) : newValue,
    );
    localUpdate(newValue);
  }

  return [value, update];
};
