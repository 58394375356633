import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from '../../components';
import { lineHeight } from '../../style';
import { Graph, Metadata } from '../../types';

const Title = styled.div`
  flex: 1;
`;

const Number = styled.div`
  color: var(--text-secondary-color);
  font-size: 1.25em;
  width: ${1.5 * lineHeight}px;
`;

const TokenID = styled.div`
  color: var(--text-secondary-color);
`;

const RowControls = styled.div`
  display: flex;
  align-items: center;
  visibility: hidden;
`;

type RowProps = {
  playing: boolean;
  dragActive: boolean;
  isDragging: boolean;
};
const Row = styled.div`
  padding: ${lineHeight / 2}px 0 ${lineHeight / 2}px ${lineHeight}px;
  ${lineHeight}px;
  display: flex;
  gap: ${lineHeight}px;
  align-items: center;
  background: var(
    ${({ playing, isDragging }: RowProps) =>
      isDragging
        ? '--background-secondary-color'
        : playing
        ? '--project-preview-color'
        : '--background-color'}
  );
  transition: background 0.1s;

  ${({ dragActive }) =>
    !dragActive &&
    `&:hover {
      background: var(--project-preview-color);
      ${RowControls} {
        visibility: visible;
      }
    }`}
`;

const Remove = styled.div`
  padding: ${lineHeight / 2}px;
  &:after {
    content: '×';
  }
  &:hover {
    color: var(--outline-color);
  }
`;
const DragHandle = styled.div`
  padding: ${lineHeight / 2}px ${lineHeight}px ${lineHeight / 2}px
    ${lineHeight / 2}px;
  &:after {
    content: '☰';
  }
`;

export type PlaylistItemProps = {
  number: number;
  graph: Graph;
  metadata: Metadata;
  isOwner: boolean;
  playing: boolean;
  play: () => void;
  deleteFromPlayist: () => void;
  dragHandleProps: any;
  dragActive: boolean;
  isDragging: boolean;
};

export const PlaylistItem: FC<PlaylistItemProps> = ({
  number,
  graph,
  metadata,
  isOwner,
  playing,
  play,
  deleteFromPlayist,
  dragHandleProps,
  dragActive,
  isDragging,
}) => {
  return (
    <Row
      onClick={play}
      playing={playing}
      dragActive={dragActive}
      isDragging={isDragging}
    >
      <Number>{number}</Number>
      <Title>
        <Link href={`/gallery/${metadata.tokenId}`}>{graph.name}</Link>
        <TokenID>#{metadata.tokenId}</TokenID>
      </Title>
      {isOwner && (
        <RowControls>
          <Remove
            onClick={e => {
              e.stopPropagation();
              deleteFromPlayist();
            }}
          />
          <DragHandle {...dragHandleProps}></DragHandle>
        </RowControls>
      )}
    </Row>
  );
};
