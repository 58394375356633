import React, { FC } from 'react';
import { GridLayout } from './GridLayout';
import { Link } from './Link';
import { maxBlockContentWidth } from '../style';
import styled from 'styled-components';

const links: [href: string, text: string, props?: any][] = [
  ['/faq', 'FAQ'],
  ['/gallery', 'Gallery'],
  [
    'https://twitter.com/audioglyphs',
    'Twitter',
    { target: '_blank', rel: 'noopener noreferrer' },
  ],
  [
    'https://discord.gg/r3h8nTzk6c',
    'Discord',
    { target: '_blank', rel: 'noopener noreferrer' },
  ],
  [
    'https://etherscan.io/address/0xfb3765e0e7ac73e736566af913fa58c3cfd686b7',
    'Smart contract',
  ],
  ['/terms', 'Terms'],
];

const FooterLink = styled(Link)`
  font-size: 1.25em;
`;

export const Footer: FC = () => (
  <GridLayout style={{ maxWidth: maxBlockContentWidth }}>
    {links.map(([href, text, props = {}], i) => (
      <div>
        <FooterLink key={i} href={href} {...props}>
          {text}
        </FooterLink>
      </div>
    ))}
  </GridLayout>
);
