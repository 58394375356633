import React, { FC, useState, memo } from 'react';
import styled from 'styled-components';
import { AudioGraph } from '../audioGraph';
import { useAnimationFrameEffect } from '../hooks';

export type CurrentTimeProps = {
  audioGraph: AudioGraph;
  frameDivisor?: number;
};

const Text = styled.span`
  color: var(--text-secondary-color);
`;

export const CurrentTime: FC<CurrentTimeProps> = memo(
  ({ frameDivisor = 3, audioGraph: { context } }) => {
    const [time, setTime] = useState<number>(context.currentTime);
    useAnimationFrameEffect(
      () => {
        setTime(context.currentTime);
      },
      [context],
      frameDivisor,
    );

    const hours = `${Math.floor(time / 3600)}`.padStart(2, '0');
    const minutes = `${Math.floor((time % 3600) / 60)}`.padStart(2, '0');
    const seconds = `${Math.floor(time % 60)}`.padStart(2, '0');

    return (
      <Text>
        {hours}:{minutes}:{seconds}
      </Text>
    );
  },
);
