import React, { FC } from 'react';
import styled from 'styled-components';
import {
  H1,
  H2,
  P,
  A,
  Link,
  GridLayout,
  Footer,
  Layout,
  AlbumCover,
} from '../../components';
import { lineHeight, maxBlockContentWidth } from '../../style';
import { Moire } from './components';
import { TAB_ORDER } from '../../utils';
import Mint from '../../components/Mint';
import { mainGraph, graphs } from './examples';

export type HomeProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
  min-width: 320px;
  overflow: auto;
`;

const MainContent = styled.div`
  display: flex;
  flex-flow: column;
  padding-top: ${2 * lineHeight}px;
  padding-bottom: ${10 * lineHeight}px;
  max-width: ${maxBlockContentWidth}px;
  align-self: center;
  align-itmes: stretch;
`;

const Block = styled.div`
  margin-bottom: ${4 * lineHeight}px;
  padding: 0 ${lineHeight}px;
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    max-width: 400px;
  }
`;

const Row = styled(Block)`
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: ${2 * lineHeight}px;
  }
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    flex-flow: column;
    & > :first-child {
      margin-right: 0;
    }
  }
`;

const RowReverse = styled(Row)`
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    flex-flow: column-reverse;
  }
`;

const Illustration = styled.div`
  flex: 3;
`;

const Description = styled.div`
  flex: 3;
`;

const MainIllustration = styled(Illustration)`
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    margin-bottom: ${4 * lineHeight}px;
  }
`;

export const Home: FC<HomeProps> = () => {
  return (
    <Layout
      title="Audioglyphs"
      breadcrumbs={
        <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
          audioglyphs
        </Link>
      }
    >
      <Container>
        <MainContent>
          <Block>
            <H1>AUDIOGLYPHS</H1>
            <P>
              10,000 randomly generated, infinite audio NFTs derived from
              on-chain{' '}
              <A
                href="https://pixelglyphs.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pixelglyph
              </A>{' '}
              data.
            </P>
            <P>
              Each Audioglyph synthesizes a unique, infinite stream of music
              directly in your browser at lossless quality.
            </P>
            <P>
              <Link href="/faq">How it works</Link>
            </P>
          </Block>

          <RowReverse>
            <Mint />
            <MainIllustration>
              <AlbumCover {...mainGraph} width={360} />
            </MainIllustration>
          </RowReverse>
          <Row>
            <Illustration>
              <Moire
                patterns={[
                  {
                    type: 'circle',
                    count: 30,
                    exponent: 1.75,
                    color1: [90, 90, 50],
                    color2: [130, 90, 60],
                  },
                ]}
              />
            </Illustration>
            <Description>
              <H2>Future of music</H2>
              <P>
                Audioglyphs are a glimpse into a future of music where instead
                of publishing statically recorded songs and limiting access,
                artists create infinite variations that are available to all to
                explore freely.
              </P>
              <P>
                Instead of buying the rights to listen to music, listeners can
                truly own a unique version.
              </P>
            </Description>
          </Row>
          <RowReverse>
            <Description>
              <H2>Open Technology</H2>
              <P>
                We plan to open source the core technology Audioglyphs uses for
                managing and embedding audio processing on the web.
              </P>
              <P>
                With the success of Audioglyphs, we plan to continue to develop
                tools for artists to create and publish generative music.
              </P>
            </Description>
            <Illustration>
              <Moire
                patterns={[
                  {
                    type: 'circle',
                    count: 40,
                    exponent: 0.5,
                    color1: [-30, 90, 50],
                    color2: [10, 90, 60],
                    scale: 4,
                    translate: [-2.5, -1.5],
                  },
                  {
                    type: 'circle',
                    count: 40,
                    exponent: 0.5,
                    color1: [10, 90, 50],
                    color2: [50, 90, 60],
                    scale: 4,
                    translate: [-0.5, -1.5],
                  },
                ]}
              />
            </Illustration>
          </RowReverse>
          <Row>
            <Illustration>
              <Moire
                patterns={[
                  {
                    type: 'line',
                    count: 30,
                    exponent: 0.5,
                    angle: -7,
                    color1: [10, 90, 50],
                    color2: [50, 90, 60],
                  },
                  {
                    type: 'line',
                    count: 30,
                    exponent: 0.5,
                    angle: 7,
                    color1: [50, 90, 50],
                    color2: [90, 90, 60],
                  },
                ]}
              />
            </Illustration>
            <Description>
              <H2>Carbon neutral</H2>
              <P>
                Carbon emissions from minting Audioglyphs were offset with a{' '}
                <A
                  href="https://etherscan.io/tx/0x4608503bd2aaffa95ccdaf0d40d547d770e03aab50255cc7b8ef3b20b066b666"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  donation
                </A>{' '}
                supporting the Devarahipparigi Wind Power Project in Karnataka,
                India facilitated by{' '}
                <A href="" target="_blank" rel="noopener noreferrer">
                  Offsetra
                </A>
                .
              </P>
            </Description>
          </Row>

          <Block>
            <H2>Listen</H2>
            <GridLayout minSize={180}>
              {graphs.map(({ graph, metadata }) => (
                <AlbumCover
                  key={graph.seed}
                  graph={graph}
                  metadata={metadata}
                />
              ))}
            </GridLayout>
            <P>Contiue to the gallery to explore the unique Audioglyphs.</P>
            <P>
              <Link href="/gallery">More</Link>
            </P>
          </Block>
          <Block>
            <H2>More</H2>
            <Footer />
          </Block>
        </MainContent>
      </Container>
    </Layout>
  );
};
