import React, { FC } from 'react';
import styled from 'styled-components';
import { lineHeight } from '../style';

const Col = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
`;

const IllustrationDummy = styled.svg`
  width: 100%;
  height: auto;
  background-color: var(--grid-color);
`;

const Row = styled.div`
  margin-top: ${lineHeight / 2}px;
  height: ${lineHeight * 2}px;
`;

export type AlbumCoverDummyProps = {};

export const AlbumCoverDummy: FC<AlbumCoverDummyProps> = () => {
  return (
    <Col>
      <IllustrationDummy height={1} width={1} />
      <Row />
    </Col>
  );
};
