import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Toolbar } from './Toolbar';
import { ToolbarGroup } from './ToolbarGroup';
import { lineHeight } from '../style';
import { WalletLink } from './WalletLink';
import { PageTitle } from './PageTitle';

export type LayoutProps = {
  title: string;
  children?: ReactNode;
  breadcrumbs?: ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
`;

const Breadcrumbs = styled.div`
  display: flex;
  white-space: pre;
  padding: 0 ${lineHeight}px;
`;

export const Layout: FC<LayoutProps> = ({ title, children, breadcrumbs }) => {
  return (
    <>
      <PageTitle title={title} />
      <Container>
        <Toolbar as="header">
          <ToolbarGroup flex>
            <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
          </ToolbarGroup>
          <ToolbarGroup right>
            <Breadcrumbs>
              <WalletLink />
            </Breadcrumbs>
          </ToolbarGroup>
        </Toolbar>
        {children}
      </Container>
    </>
  );
};
