import { TriggerEvent } from '../audioNodeUtils';

export class ADEnvelopeNode extends AudioWorkletNode {
  readonly clock: AudioParam;
  readonly attack: AudioParam;
  readonly decay: AudioParam;
  private _hold: boolean = false;

  constructor(
    context: BaseAudioContext,
    {
      attack = 0.001,
      decay = 0.5,
      hold = false,
    }: {
      attack?: number;
      decay?: number;
      hold?: boolean;
    } = {},
  ) {
    super(context, 'ADEnvelopeProcessor', {
      numberOfInputs: 0,
      numberOfOutputs: 1,
      outputChannelCount: [1],
      channelCount: 1,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
      parameterData: { attack, decay },
      processorOptions: { hold },
    });
    this.port.onmessage = () => {
      this.dispatchEvent(new TriggerEvent());
    };

    // @ts-ignore
    this.clock = this.parameters.get('clock');
    // @ts-ignore
    this.attack = this.parameters.get('attack');
    // @ts-ignore
    this.decay = this.parameters.get('decay');
  }

  get hold(): boolean {
    return this._hold;
  }
  set hold(value: boolean) {
    this._hold = value;
    this.port.postMessage(value);
  }
}
