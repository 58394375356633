import { Graph, Rect, Vector, min, max, add, subtract } from '../types';
import { getNodeSize } from './getNodeSize';

export const graphBounds = (graph: Graph): Rect => {
  const nodes = Object.values(graph.nodes);
  if (nodes.length === 0)
    throw new Error('cannot calculate bounds of empty graph');
  const minimum = nodes.reduce(
    (memo, { data: { position } }) => min(memo, position),
    [Infinity, Infinity] as Vector,
  );
  const maximum = nodes.reduce(
    (memo, node) => max(memo, add(node.data.position, getNodeSize(node))),
    [-Infinity, -Infinity] as Vector,
  );

  return [minimum, subtract(maximum, minimum)];
};
