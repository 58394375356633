import React, { FC } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { randomGraph } from '../../randomGraph';
import { Graph, Metadata, Playlist } from '../../types';
import { PlaylistItem } from './PlaylistItem';

export type ListProps = {
  playlist: Playlist;
  isOwner: boolean;
  position: number;
  play: (graph: Graph, metadata: Metadata, index: number) => void;
  deleteFromPlaylist: (index: number) => void;
  reorderPlaylist: (initialIndex: number, finalIndex: number) => void;
};

export const List: FC<ListProps> = ({
  playlist,
  isOwner,
  position,
  play,
  deleteFromPlaylist,
  reorderPlaylist,
}) => {
  return (
    <DragDropContext
      onDragEnd={result => {
        if (result.destination) {
          reorderPlaylist(result.source.index, result.destination.index);
        }
      }}
    >
      <Droppable droppableId="list">
        {(provided, droppableSnapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {playlist.glyphs.map((glyph, i) => {
              const graph = randomGraph(glyph.seed);
              return (
                <Draggable
                  key={glyph.tokenId}
                  draggableId={`${glyph.tokenId}`}
                  index={i}
                  isDragDisabled={!isOwner}
                >
                  {(provided, draggableSnapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <PlaylistItem
                        number={i + 1}
                        graph={graph}
                        metadata={glyph}
                        isOwner={isOwner}
                        playing={position === i}
                        play={() => play(graph, glyph, i)}
                        deleteFromPlayist={() => deleteFromPlaylist(i)}
                        dragHandleProps={provided.dragHandleProps}
                        dragActive={droppableSnapshot.isDraggingOver}
                        isDragging={draggableSnapshot.isDragging}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
