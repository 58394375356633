import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { H2 } from './H2';
import { SectionsLayout } from './SectionsLayout';
import { Link } from './Link';
import { Footer } from './Footer';
import { TAB_ORDER } from '../utils';

const HeaderLink = styled.a`
  text-decoration: none;
`;

export type SectionsContentLayoutProps = {
  title: string;
  path: string;
  sections: {
    id: string;
    title: string;
    content: ReactNode;
  }[];
};

export const SectionsContentLayout: FC<SectionsContentLayoutProps> = ({
  title,
  path,
  sections,
}) => {
  return (
    <SectionsLayout
      title={`Audioglyphs - ${title}`}
      breadcrumbs={
        <>
          <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
            audioglyphs
          </Link>
          {' / '}
          <Link plain href={path} tabIndex={TAB_ORDER.HEADER_BUTTON}>
            {title}
          </Link>
        </>
      }
      sections={sections.map(({ id, title, content }, i) => ({
        id,
        title,
        content: (
          <>
            <HeaderLink href={`#${id}`}>
              <H2 as={i === 0 ? 'h1' : 'h2'} style={{ marginTop: 0 }}>
                {title}
              </H2>
            </HeaderLink>
            {content}
          </>
        ),
      }))}
      footer={
        <>
          <H2 style={{ marginTop: 0 }}>More</H2>
          <Footer />
        </>
      }
    />
  );
};
