import { randomGraph } from '../../randomGraph';

export const mainGraph = {
  graph: randomGraph(1608835195587616),
  metadata: {
    matrix: [
      [0, 0, 0, 0, 0],
      [0, 1, 1, 1, 0],
      [0, 0, 0, 0, 1],
      [0, 1, 1, 1, 0],
      [0, 1, 0, 1, 1],
      [0, 0, 0, 1, 1],
      [0, 0, 0, 0, 1],
      [0, 1, 0, 0, 1],
      [0, 1, 1, 0, 1],
      [0, 0, 0, 0, 0],
    ],
    tokenId: 19,
    seed: 1608835195587616,
  },
};

export const graphs = [
  {
    graph: randomGraph(1559409836961245),
    metadata: {
      matrix: [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 1, 1, 1, 1],
        [0, 1, 0, 1, 1],
        [0, 1, 1, 1, 0],
        [0, 1, 0, 1, 1],
        [0, 1, 1, 0, 0],
        [0, 1, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
      ],
      tokenId: 85,
      seed: 1559409836961245,
    },
  },
  {
    graph: randomGraph(5580733074524847),
    metadata: {
      matrix: [
        [0, 0, 0, 0, 0],
        [0, 1, 1, 0, 0],
        [0, 1, 1, 0, 0],
        [0, 0, 0, 0, 1],
        [0, 1, 0, 1, 1],
        [0, 1, 1, 0, 1],
        [0, 1, 1, 1, 1],
        [0, 1, 1, 0, 1],
        [0, 1, 1, 1, 1],
        [0, 0, 0, 0, 0],
      ],
      tokenId: 63,
      seed: 5580733074524847,
    },
  },
  {
    graph: randomGraph(2522047240298247),
    metadata: {
      matrix: [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 1, 0, 0, 1],
        [0, 0, 1, 1, 1],
        [0, 1, 1, 1, 1],
        [0, 1, 0, 0, 1],
        [0, 1, 1, 1, 1],
        [0, 0, 1, 0, 1],
        [0, 1, 1, 1, 0],
        [0, 0, 0, 0, 0],
      ],
      tokenId: 68,
      seed: 2522047240298247,
    },
  },
];
