import React, { FC, useState, useEffect, useRef } from 'react';
import { GlobalStyle } from './GlobalStyle';
import { Router } from './Router';
import {
  Home,
  FAQ,
  Gallery,
  Player,
  Playlist,
  Terms,
  Wallet,
  Stems,
} from '../pages';
import { NotFoundPage } from './NotFoundPage';
import { PlayerControls } from './PlayerControls';
import { AudioGraphContext } from '../contexts';
import { AudioGraph } from '../audioGraph';
import Web3Provider from './Web3Provider';
import { Graph, Metadata } from '../types';

export type RootProps = {};

export type RootState = {
  audioGraph: AudioGraph;
  graph: Graph;
  metadata: Metadata;
};

export const Root: FC<RootProps> = () => {
  const [state, setState] = useState<RootState | null>(null);
  const context = state?.audioGraph.context as AudioContext | undefined;
  const [playing, setPlaying] = useState(context?.state === 'running');
  const updatingRef = useRef<boolean>(false);
  useEffect(() => {
    if (context === undefined) return;

    const onKeypress = (e: KeyboardEvent) => {
      if (e.key === ' ' && (e.target as HTMLElement).tagName !== 'INPUT') {
        e.preventDefault();
        if (context.state === 'running') {
          setPlaying(false);
          context.suspend();
        } else {
          setPlaying(true);
          context.resume();
        }
      }
    };
    window.addEventListener('keypress', onKeypress);

    return () => {
      window.removeEventListener('keypress', onKeypress);
    };
  }, [context]);

  return (
    <>
      <GlobalStyle />
      <Web3Provider>
        <AudioGraphContext.Provider
          value={{
            audioGraph: state?.audioGraph ?? null,
            graph: state?.graph ?? null,
            metadata: state?.metadata ?? null,
            playing,
            play: (graph, metadata) => {
              if (updatingRef.current) return;

              setPlaying(true);
              if (state?.graph !== graph) {
                updatingRef.current = true;
                const audioGraph = new AudioGraph(graph);
                audioGraph.ready.then(() => {
                  setState({
                    graph,
                    metadata,
                    audioGraph,
                  });
                  (audioGraph.context as AudioContext).resume();
                  state?.audioGraph.close();
                  updatingRef.current = false;
                });
              } else {
                context?.resume();
              }
            },
            pause: () => {
              setPlaying(false);
              context?.suspend();
            },
            stop: () => {
              if (updatingRef.current) return;

              setPlaying(false);
              if (state) {
                updatingRef.current = true;
                const audioGraph = new AudioGraph(state.graph);
                audioGraph.ready.then(() => {
                  setState({ ...state, audioGraph });
                  state?.audioGraph.close();
                  updatingRef.current = false;
                });
              }
            },
          }}
        >
          <Router
            routes={[
              [/^\/$/, Home],
              [/^\/faq$/, FAQ],
              [/^\/terms$/, Terms],
              [/^\/gallery$/, Gallery],
              [/^\/gallery\/\d{1,16}$/, Player],
              [/^\/gallery\/\d{1,16}\/stems$/, Stems],
              [/^\/wallet\/0[xX][0-9a-fA-F]+$/, Wallet],
              [/^\/playlist\/0[xX][0-9a-fA-F]+\/[0-9a-fA-F]+$/, Playlist],
            ]}
            NotFound={() => <NotFoundPage />}
          >
            <PlayerControls />
          </Router>
        </AudioGraphContext.Provider>
      </Web3Provider>
    </>
  );
};
