import { useState } from 'react';
import { Response } from '../types';

export const useAPIAction = <Data, Args extends any[]>(
  fn: (...args: Args) => Promise<Response<Data>>,
): [(...args: Args) => Promise<Response<Data>>, boolean, string | null] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  return [
    async (...args): Promise<Response<Data>> => {
      setLoading(true);
      const response = await fn(...args);
      setError(response.error);
      setLoading(false);
      return response;
    },
    loading,
    error,
  ];
};
