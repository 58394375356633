import { RhythmStep } from '../types';
import { TriggerEvent } from '../audioNodeUtils';

export class RhythmNode extends AudioWorkletNode {
  readonly clock: AudioParam;
  readonly reset: AudioParam;
  private _sequence: RhythmStep[];
  private _position: number = 0;

  constructor(
    context: BaseAudioContext,
    { sequence = [] }: { sequence?: RhythmStep[] },
  ) {
    super(context, 'RhythmProcessor', {
      numberOfInputs: 0,
      numberOfOutputs: 1,
      outputChannelCount: [1],
      channelCount: 1,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
      processorOptions: {
        sequence: new Uint8Array(sequence),
      },
    });
    this.port.onmessage = ({ data }) => {
      const activating =
        this._sequence[data] !== RhythmStep.Off &&
        this._sequence[this._position] !== RhythmStep.Hold;

      this._position = data;

      this.dispatchEvent(new TriggerEvent(activating));
    };

    this._sequence = sequence;

    // @ts-ignore
    this.clock = this.parameters.get('clock');
    // @ts-ignore
    this.reset = this.parameters.get('reset');
  }

  get position(): number {
    return this._position;
  }
  get sequence(): RhythmStep[] {
    return this._sequence;
  }
  set sequence(sequence: RhythmStep[]) {
    this._sequence = sequence;
    this._position =
      sequence.length === 0 ? 0 : this._position % sequence.length;
    this.port.postMessage(new Uint8Array(sequence));
  }
}
