import { web3Util, request } from '../utils';
import { Response } from '../types';

export const addToPlaylist = async (
  playlistID: number,
  tokenId: number,
): Promise<Response<void>> => {
  const { address, signature, timestamp } = await web3Util.getSignature();
  return request<void>(
    `audioglyphs/wallet/${address}/playlists/${playlistID}/glyphs`,
    'POST',
    { signature, timestamp, tokenId },
  );
};
