import { createContext } from 'react';

export type Web3Options = {
  address: string | null;
  hasProvider: boolean;
};

export const Web3Context = createContext<Web3Options>({
  address: null,
  hasProvider: false,
});
