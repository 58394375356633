import { web3Util, request } from '../utils';
import { Response } from '../types';

export const updatePlaylist = async (
  playlistId: string,
  name: string,
  glyphs: number[],
): Promise<Response<void>> => {
  const { address, signature, timestamp } = await web3Util.getSignature();
  return request<void>(
    `audioglyphs/wallet/${address}/playlists/${playlistId}`,
    'PUT',
    {
      signature,
      timestamp,
      name,
      glyphs,
    },
  );
};
