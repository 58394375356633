import { GraphForStorage, Node, Edge } from '../types';

export const setNamesAsIDs = (graph: GraphForStorage): GraphForStorage => {
  return {
    ...graph,
    nodes: Object.values(graph.nodes).reduce((memo, node) => {
      memo[node.data.name] = { ...node, id: node.data.name };
      return memo;
    }, {} as { [id: string]: Node }),
    edges: Object.values(graph.edges).reduce((memo, edge) => {
      const fromNode = graph.nodes[edge.from.node];
      const toNode = graph.nodes[edge.to.node];
      const edgeId = `${fromNode.data.name}_${edge.from.index}_TO_${toNode.data.name}_${edge.to.index}`;
      memo[edgeId] = {
        id: edgeId,
        from: { ...edge.from, node: fromNode.data.name },
        to: { ...edge.to, node: toNode.data.name },
      };
      return memo;
    }, {} as { [id: string]: Edge }),
  };
};
