import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Link,
  NetworkActivityIndicator,
  Center,
  H1,
  AlbumCover,
  AlbumCoverDummy,
  A,
} from '../../components';
import { TAB_ORDER, shortAddress } from '../../utils';
import { lineHeight, maxBlockContentWidth } from '../../style';
import { useAPIData } from '../../hooks';
import { Graph, Metadata, Playlist as PlaylistModel } from '../../types';
import { randomGraph } from '../../randomGraph';
import { AudioGraphContext, RoutingContext, Web3Context } from '../../contexts';
import { PlaybackControls } from './PlaybackControls';
import { useAPIAction } from '../../hooks/useAPIAction';
import { deletePlaylist, updatePlaylist } from '../../apiActions';
import { EditNameDialog } from './EditNameDialog';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { List } from './List';

const Container = styled.div`
  padding: ${lineHeight}px ${2 * lineHeight}px ${8 * lineHeight}px;
  overflow-y: auto;
`;

const Title = styled(H1)`
  word-wrap: break-word;
  display: inline;
  margin: 0;
`;

const Author = styled.div`
  margin: ${lineHeight}px 0 ${2 * lineHeight}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const OwnerControls = styled.div`
  display: flex;
  gap: ${lineHeight}px;
  margin: ${lineHeight}px 0 ${2 * lineHeight}px;
`;

const Columns = styled.div`
  display: flex;
  gap: ${lineHeight}px;
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  max-width: 420px;
  flex: 1;
`;

const RightColumn = styled.div`
  max-width: 420px;
  flex: 1;
`;

export type PlaylistProps = {
  path: string[];
};

export const Playlist: FC<PlaylistProps> = ({ path: [, address, id] }) => {
  const { address: ownAddress } = useContext(Web3Context);
  const { route } = useContext(RoutingContext);
  const {
    graph: playingGraph,
    metadata: playingMetadata,
    play,
    playing,
    audioGraph,
  } = useContext(AudioGraphContext);
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(Infinity);
  const [shuffle, setShuffle] = useState(false);
  const [autoAdvanceStartOffset, setAutoAdvanceStartOffset] = useState(0);
  const [editNameDialogActive, setEditNameDialogActive] = useState(false);
  const [confirmDeleteDialogActive, setConfirmDeleteDialogActive] = useState(
    false,
  );

  const [loading, error, data, setData] = useAPIData<PlaylistModel>(
    `audioglyphs/wallet/${address}/playlists/${id}`,
    [address, id],
  );
  const [
    callUpdatePlaylist,
    updatePlaylistLoading,
    updatePlaylistError,
  ] = useAPIAction(updatePlaylist);
  const [
    callDeletePlaylist,
    deletePlaylistLoading,
    deletePlaylistError,
  ] = useAPIAction(deletePlaylist);

  // initially set position based on playing graph
  useEffect(() => {
    if (data) {
      const playingPosition = data.glyphs.findIndex(
        g => g.tokenId === playingMetadata?.tokenId,
      );
      if (playingPosition > -1) setPosition(playingPosition);
    }
  }, [data]);

  // handle auto advance
  useEffect(() => {
    if (data && audioGraph) {
      const i = setInterval(() => {
        if (
          (audioGraph.context.currentTime ?? 0) - autoAdvanceStartOffset >
          duration * 60
        ) {
          const currentPosition = position ?? 0;
          let nextPosition;
          if (shuffle) {
            do {
              nextPosition = Math.floor(Math.random() * data.glyphs.length);
            } while (
              nextPosition === currentPosition &&
              data.glyphs.length > 1
            );
          } else {
            nextPosition = (1 + currentPosition) % data.glyphs.length;
          }
          const metadata = data.glyphs[nextPosition];
          const graph = randomGraph(metadata.seed);
          play(graph, metadata);
          setPosition(nextPosition);
          setAutoAdvanceStartOffset(0);
        }
      }, 100);
      return () => clearInterval(i);
    }
    return undefined;
  }, [audioGraph, duration, shuffle, data, position]);

  // determine if current user is owner and can edit
  const isOwner = address === ownAddress;

  // determine which graph to show in player
  let mainGraph: Graph | null = null;
  let mainMetadata: Metadata | null = null;
  if (data && data.glyphs.find(g => g.tokenId === playingMetadata?.tokenId)) {
    mainMetadata = playingMetadata;
    mainGraph = playingGraph;
  } else if (data) {
    mainMetadata = data.glyphs[position];
    mainGraph = randomGraph(mainMetadata.seed);
  }

  // play/pause on spacebar
  useEffect(() => {
    // this uses keydown and prevents default to override global play/pause
    // defined in root component and listening to keypress
    const onKeydown = (e: KeyboardEvent) => {
      if (
        e.key === ' ' &&
        !playing &&
        mainGraph &&
        mainMetadata &&
        (e.target as HTMLElement).tagName !== 'INPUT'
      ) {
        e.stopPropagation();
        e.preventDefault();
        play(mainGraph, mainMetadata);
      }
    };
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, [play, playing, mainGraph, mainMetadata]);

  return (
    <>
      {data && editNameDialogActive && (
        <EditNameDialog
          close={() => setEditNameDialogActive(false)}
          onSubmit={async name => {
            const response = await callUpdatePlaylist(
              id,
              name,
              data.glyphs.map(g => g.tokenId),
            );
            if (response.status === 204) {
              setData({ ...data, name });
              setEditNameDialogActive(false);
              return null;
            } else {
              return response.error;
            }
          }}
          initialValue={data.name}
        />
      )}
      {confirmDeleteDialogActive && (
        <ConfirmDeleteDialog
          close={() => setConfirmDeleteDialogActive(false)}
          loading={deletePlaylistLoading}
          error={deletePlaylistError}
          onConfirm={async () => {
            const response = await callDeletePlaylist(id);
            if (response.status === 204) {
              route(`/wallet/${address}`);
            }
          }}
        />
      )}
      <Layout
        title={data ? `Audioglyphs - ${data.name}` : 'Audioglyphs'}
        breadcrumbs={
          <>
            <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
              audioglyphs
            </Link>
            {' / '}
            <Link
              plain
              href={`/wallet/${address}`}
              tabIndex={TAB_ORDER.HEADER_BUTTON}
            >
              {shortAddress(address)}
            </Link>
            {' / '}
            <Link
              plain
              href={`/playlist/${address}/${id}`}
              tabIndex={TAB_ORDER.HEADER_BUTTON}
            >
              {data?.name}
            </Link>
          </>
        }
      >
        {data && position !== null ? (
          <Container>
            <Title>{data.name}</Title>
            {isOwner ? (
              <OwnerControls>
                Your playlist
                <A
                  onClick={() => setEditNameDialogActive(true)}
                  tabIndex={TAB_ORDER.INPUT_SECONDARY}
                >
                  Edit name
                </A>
                <A
                  onClick={() => setConfirmDeleteDialogActive(true)}
                  tabIndex={TAB_ORDER.INPUT_SECONDARY}
                >
                  Delete
                </A>
                <NetworkActivityIndicator
                  pending={false}
                  active={updatePlaylistLoading}
                  error={false}
                />
              </OwnerControls>
            ) : (
              <Author>
                By <Link href={`/wallet/${address}`}>{address}</Link>
              </Author>
            )}
            <Columns>
              <LeftColumn>
                {mainMetadata && mainGraph ? (
                  <AlbumCover graph={mainGraph} metadata={mainMetadata} />
                ) : (
                  <AlbumCoverDummy />
                )}
                <PlaybackControls
                  duration={duration}
                  setDuration={(n: number) => {
                    setDuration(n);
                    if (n === Infinity) setAutoAdvanceStartOffset(0);
                    else {
                      const time = audioGraph?.context.currentTime ?? 0;
                      const adjTime = time - autoAdvanceStartOffset;
                      const target = adjTime / duration;
                      const actual = adjTime / n;
                      const adjustment = (actual - target) * n;
                      const final = autoAdvanceStartOffset + adjustment;
                      setAutoAdvanceStartOffset(final);
                    }
                  }}
                  shuffle={shuffle}
                  setShuffle={setShuffle}
                  autoAdvanceStartOffset={autoAdvanceStartOffset}
                  playing={
                    mainMetadata !== null &&
                    mainMetadata?.tokenId === playingMetadata?.tokenId
                  }
                />
              </LeftColumn>
              <RightColumn>
                <List
                  playlist={data}
                  isOwner={isOwner}
                  position={position}
                  play={(graph, glyph, i) => {
                    play(graph, glyph);
                    setPosition(i);
                  }}
                  deleteFromPlaylist={i => {
                    const nextGlyphs = [
                      ...data.glyphs.slice(0, i),
                      ...data.glyphs.slice(i + 1),
                    ];
                    callUpdatePlaylist(
                      id,
                      data.name,
                      nextGlyphs.map(g => g.tokenId),
                    );
                    setData({ ...data, glyphs: nextGlyphs });
                  }}
                  reorderPlaylist={(from, to) => {
                    let nextGlyphs = data.glyphs.slice();
                    const [mover] = nextGlyphs.splice(from, 1);
                    nextGlyphs.splice(to, 0, mover);
                    callUpdatePlaylist(
                      id,
                      data.name,
                      nextGlyphs.map(g => g.tokenId),
                    );
                    setData({ ...data, glyphs: nextGlyphs });

                    if (from === position) {
                      setPosition(to);
                    } else if (from > position && to <= position) {
                      setPosition(position + 1);
                    } else if (from < position && to >= position) {
                      setPosition(position - 1);
                    }
                  }}
                />
              </RightColumn>
            </Columns>
          </Container>
        ) : (
          <Center>
            <NetworkActivityIndicator
              pending={false}
              active={loading || deletePlaylistLoading}
              error={!!error || !!deletePlaylistError || !!updatePlaylistError}
            />
          </Center>
        )}
      </Layout>
    </>
  );
};
