import React, { FC } from 'react';
import { SectionsContentLayout, P, A } from '../../components';

export const FAQ: FC = () => {
  return (
    <SectionsContentLayout
      title="FAQ"
      path="/faq"
      sections={[
        {
          id: 'faq',
          title: 'FAQ',
          content: <></>,
        },
        {
          id: 'how-is-each-audioglyph-unique',
          title: 'How is each Audioglyph unique?',
          content: (
            <>
              <P>
                On-chain data seeds a{' '}
                <A href="https://en.wikipedia.org/wiki/Pseudorandom_number_generator">
                  pseudo random number generator
                </A>{' '}
                which is used to set parameters on nodes in a signal processing
                graph.
              </P>
              <P>
                The structure of this graph and the probability distributions of
                the parameters have been carefully designed to balance
                generating a wide range of sounds with ensuring that every
                Audioglyph is musical and interesting.
              </P>
            </>
          ),
        },
        {
          id: 'what-does-it-mean-for-audioglyphs-to-be-infinite',
          title: 'What does it mean for Audioglyphs to be infinite?',
          content: (
            <>
              <P>
                While there is a small chance that some Audioglyphs will repeat,
                the melodic sequences in most Audioglyphs are based on
                irrational numbers and will continue forever without repeating.
              </P>
            </>
          ),
        },
        {
          id: 'how-do-audioglyphs-run-in-the-browser',
          title: 'How do Audioglyphs run in the browser?',
          content: (
            <>
              <P>
                Audioglyphs run in the browser using the{' '}
                <A href="https://www.w3.org/TR/webaudio/">web audio</A>{' '}
                standard, which is recently fully supported across common
                browsers. Their processing graph makes use of both native nodes
                implemented by the browser and custom nodes written in
                javascript and in c++ compiled to run in the browser using{' '}
                <A href="https://webassembly.org/">web assembly</A>.
              </P>
              <P>
                The audio generated locally is entirely uncompressed and
                playback quality is lossless. The code required to play back all
                10,000 glyphs is only a few hundred kilobytes - around the same
                size as two seconds of lossless streaming on apple music.
              </P>
              <P>
                Running in browser is important for local generation to be
                practical - it provides cross platform audio libraries and its
                sandbox resolves the security issues that would be present with
                running untrusted native code to generate audio.
              </P>
            </>
          ),
        },
        {
          id: 'how-does-the-sound-generation-work',
          title: 'How does the sound generation work?',
          content: (
            <>
              <P>
                Audio is produced by a DSP graph with all modulation and
                sequencing accomplished using continuous audio signals. This is
                an approach common in analog modular synthesizers but different
                from most music creation software.
              </P>
              <P>
                Melodies are derived from a{' '}
                <A href="https://en.wikipedia.org/wiki/Moir%C3%A9_pattern">
                  moiré pattern
                </A>{' '}
                between two unrelated frequencies. This approach allows complex
                patterns to emerge from few simple inputs. A clock oscillator
                controls sampling frequency - each cycle of this oscillator
                triggers a value to be sampled from a second oscillator running
                at an incommensurable frequency. The sampled value is then
                quantized to a value in a randomized scale. When the quantized
                value changes from the previous step, an envelope is triggered
                to modulate the amplitude and filter frequency of the main
                voice.{' '}
              </P>
              <P>
                Drum sounds are based on PCM samples of a{' '}
                <A href="https://en.wikipedia.org/wiki/Roland_TR-606">
                  Roland TR-606
                </A>{' '}
                drum machine recorded to tape before being re-recorded
                digitally. These samples are randomly pitched and high pass
                filtered to produce variation between glyphs. The rhythms for
                the three drum voices are randomly generated, but with
                probabilities skewed towards common patterns in popular music.
                Many glyphs incorporate{' '}
                <A href="https://en.wikipedia.org/wiki/Metre_(music)#Polymetre">
                  polymeter
                </A>{' '}
                to create more complex rhythms from a few simple phrases.
              </P>
            </>
          ),
        },
        {
          id: 'how-do-the-visualizations-work',
          title: 'How do the visualizations work?',
          content: (
            <>
              <P>
                Audioglyph visualizations are based on the same{' '}
                <A href="https://en.wikipedia.org/wiki/Moir%C3%A9_pattern">
                  moiré pattern
                </A>{' '}
                used to generate melodies. Two sets of overlapping concentric
                circles move against each other, creating complex interferance
                patterns.
              </P>
              <P>
                Properties like the speed and extent of the motion and the
                number of circles are based on parameters of the audio.
                Additional animations are triggered based on events passed from
                the audio thread.
              </P>
              <P>
                Colors used in the visiualization are generated using the{' '}
                <A href="https://www.hsluv.org/">hsluv</A> color space.
              </P>
            </>
          ),
        },
        {
          id: 'how-are-audioglyphs-related-to-pixelglyphs',
          title: 'How are Audioglyphs related to Pixelglyphs?',
          content: (
            <>
              <P>
                Each Audioglyph seed is created using the pixel matrix from its
                corresponding Pixelglyph. The numbers that make up the matrix
                and the minting transaction hash of the Audioglyph are converted
                to binary numbers and multiplied. The final seed is the product
                of this multiplication modulo 9007199254740991.
              </P>
              <P>
                Pixelglyphs also inspired Audioglyphs thematically - when
                composing this project we imagined Pixelglyphs as low-fi
                characters and tried to create their soundtrack.
              </P>
            </>
          ),
        },
        {
          id: 'what-do-you-mean-by-future-of-music',
          title: 'What do you mean by future of music?',
          content: (
            <>
              <P>
                "Future of music" is a little hyperbolic, but we are
                experimenting with some things in Audioglyphs that we think are
                first steps in exciting directions.
              </P>
              <P>
                One of these is local synthesis of generative music - this makes
                the large series of Audioglyphs with infinite duration possible
                with small file sizes and high audio quality. Audioglyphs
                variation is based on randomization with seed data from the
                blockchain, but one of the exciting things about local synthesis
                is that it can allow other types of customization based on
                things like heart rate, weather, or surroundings.
              </P>
              <P>
                We think the most important next step for local synthesis is for
                a common standard for publishing and playing music to be adopted
                that allows diverse inputs but preserves privacy. WebAudio is an
                ideal foundation for this standard because of its multiple
                implementations and the security features built into the web
                platform.
              </P>
              <P>
                Composing generative music for local synthesis has a really
                different artistic process than normal songwriting. It requires
                you to think about the space of all possible songs that you
                would like to create and sketch out its boundaries. For
                audioglyphs we did this in code, but in the future this will
                need new tools designed for more creative workflows.
              </P>
            </>
          ),
        },
        {
          id: 'what-do-you-plan-to-open-source',
          title: 'What do you plan to open source?',
          content: (
            <>
              <P>
                As part of the development of Audioglyphs, we created a library
                for managing web audio graphs in a functional style with
                straighforward and efficent updates and to handle some common
                setup tasks like loading audio files and worklet processors.
              </P>
              <P>
                We are currently working on decoupling this from Audioglyph
                specific code in order to open source it. We hope that it will
                be useful to others working on similar projects.
              </P>
            </>
          ),
        },
        {
          id: 'what-open-source-libraries-do-audioglyphs-use',
          title: 'What open source libraries do Audioglyphs use?',
          content: (
            <>
              <P>
                Audioglyph's reverb is based on open source code from{' '}
                <A href="https://mutable-instruments.net/">
                  Mutable Instruments
                </A>{' '}
                eurorack modules. These modules are a joy to use and an endless
                source of inspiration, both to create music and to write
                software like Audioglyphs.
              </P>
              <P>
                Audioglyphs also uses{' '}
                <A href="https://ethereum.org/en/developers/docs/apis/javascript/">
                  Web3
                </A>{' '}
                for interaction with the ethereum blockchain,{' '}
                <A href="https://reactjs.org/">React</A> and{' '}
                <A href="https://styled-components.com/">Styled Components</A>{' '}
                for UI, and <A href="https://www.hsluv.org/">HSLUV</A> to
                generate its color scheme.
              </P>
            </>
          ),
        },
        {
          id: 'how-are-audioglyphs-carbon-neutral',
          title: 'How are Audioglyphs carbon neutral?',
          content: (
            <>
              <P>
                We{' '}
                <A href="https://etherscan.io/tx/0x4608503bd2aaffa95ccdaf0d40d547d770e03aab50255cc7b8ef3b20b066b666">
                  purchased
                </A>{' '}
                a carbon offset through{' '}
                <A href="https://offsetra.com/">Offsetra</A> covering emissions
                from the minting of Audioglyphs.
              </P>
            </>
          ),
        },
        {
          id: 'how-many-did-the-team-mint',
          title: 'How many did the team mint?',
          content: (
            <>
              <P>One hundred Audioglyphs were reserved for the team.</P>
            </>
          ),
        },
      ]}
    />
  );
};
