import {
  IO,
  Node,
  Vector,
  IOType,
  nodeDescriptions,
  AudioNodeType,
} from '../types';

export const getIOCenterPosition = (node: Node, io: IO): Vector => {
  return getRawIOCenterPosition(node.audioNode.type, io.type, io.index);
};

export const getRawIOCenterPosition = (
  nodeType: AudioNodeType,
  ioType: IOType,
  ioIndex: number,
): Vector => {
  const nodeDescription = nodeDescriptions[nodeType];
  const count =
    ioType === IOType.Input
      ? nodeDescription.numberOfInputsAndAudioParams
      : nodeDescription.numberOfOutputs;
  const size = Math.max(
    3,
    nodeDescription.numberOfInputsAndAudioParams,
    nodeDescription.numberOfOutputs,
  );

  const paddingAmount = 2 / 3;

  return [
    ioType === IOType.Input ? paddingAmount : 3 - paddingAmount,
    count === 1
      ? size / 2
      : paddingAmount + (ioIndex * (size - 2 * paddingAmount)) / (count - 1),
  ];
};
