import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Center,
  Layout,
  Link,
  NetworkActivityIndicator,
  ValidatedForm,
} from '../../components';
import { useAPIData } from '../../hooks';
import { TAB_ORDER } from '../../utils';
import { randomGraph } from '../../randomGraph';
import { Metadata, Graph } from '../../types';
import { lineHeight } from '../../style';
import { downloadAudioFile } from '../../audioGraph';
import { BlockingOverlay } from '../../components/BlockingOverlay';

const Container = styled.div`
  padding: ${lineHeight}px ${2 * lineHeight}px;
  position: relative;
  overflow-y: auto;
`;

export type StemsProps = {
  path: string[];
};

const notes: [string, number][] = 'A A# B C C# D D# E F F# G G# A'
  .split(' ')
  .map((n, i) => {
    return [n, 220 * Math.pow(2, i / 12)];
  });

export const Stems: FC<StemsProps> = ({ path }) => {
  const tokenID = parseInt(path[1]);
  const [renderActive, setRenderActive] = useState(false);
  const [loading, error, metadata] = useAPIData<Metadata>(
    Number.isNaN(tokenID) ? undefined : `audioglyphs/metadata/${tokenID}`,
    [tokenID],
  );
  const { graph, baseFrequency, snapNote, snapFrequency } = useMemo((): {
    graph: Graph | null;
    baseFrequency: number;
    snapNote: string;
    snapFrequency: number;
  } => {
    if (metadata) {
      const graph = randomGraph(metadata.seed);
      const baseFrequency = (graph.nodes.OSC.audioNode.params as any).frequency;
      const [snapNote, snapFrequency] = notes.reduce(
        (memo, note) =>
          Math.abs(memo[1] - baseFrequency) < Math.abs(note[1] - baseFrequency)
            ? memo
            : note,
        ['', Infinity] as [string, number],
      );
      return { graph, baseFrequency, snapNote, snapFrequency };
    } else {
      return {
        graph: null,
        baseFrequency: NaN,
        snapNote: '',
        snapFrequency: NaN,
      };
    }
  }, [metadata]);

  return (
    <Layout
      title={graph ? `Audioglyphs - ${graph.name} stems` : 'Audioglyphs'}
      breadcrumbs={
        <>
          <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
            audioglyphs
          </Link>
          {' / '}
          <Link plain href="/gallery" tabIndex={TAB_ORDER.HEADER_BUTTON}>
            gallery
          </Link>
          {' / '}
          {graph && (
            <>
              <Link
                plain
                href={`/gallery/${tokenID}`}
                tabIndex={TAB_ORDER.HEADER_BUTTON}
              >
                {graph?.name}
              </Link>
              {' / '}
              <Link
                plain
                href={`/gallery/${tokenID}/stems`}
                tabIndex={TAB_ORDER.HEADER_BUTTON}
              >
                stems
              </Link>
            </>
          )}
        </>
      }
    >
      {metadata && graph ? (
        <Container>
          <ValidatedForm
            setLoading={setRenderActive}
            onSubmit={async ({
              duration,
              snapTuning,
              muteVoice,
              muteKick,
              muteSnare,
              muteHighHat,
            }) => {
              const numberOfChannels = 2;
              const sampleRate = 44100;
              const bitDepth = 16;
              const fadeIn = 0;
              const fadeOut = 0.005;

              const graph = randomGraph(metadata.seed);
              const nodes = graph.nodes as any;

              if (snapTuning === 'y') {
                const freqRatio = snapFrequency / baseFrequency;
                nodes.OSC.audioNode.params.frequency *= freqRatio;
                nodes.FLT.audioNode.params.frequency *= freqRatio;
              }
              if (muteVoice === 'y') {
                nodes.QNT.audioNode.params.values = [0];
              }
              if (muteKick === 'y') {
                nodes.BD_RTM.audioNode.params.sequence = [0];
              }
              if (muteSnare === 'y') {
                nodes.SNR_RTM.audioNode.params.sequence = [0];
              }
              if (muteHighHat === 'y') {
                nodes.HH_RTM.audioNode.params.sequence = [0];
              }

              await downloadAudioFile(
                graph,
                numberOfChannels,
                sampleRate,
                bitDepth,
                parseFloat(duration),
                fadeIn,
                fadeOut,
              );
              return null;
            }}
            fields={{
              duration: {
                label: 'Duration (seconds)',
                pattern: /^[\d]*\.?[\d]*$/,
              },
              snapTuning: {
                label: `Snap tuning (root ${baseFrequency.toFixed(
                  0,
                )}hz, snap to ${snapNote} at ${snapFrequency.toFixed(0)}hz)`,
                type: 'radio',
                options: {
                  n: 'No',
                  y: 'Yes',
                },
              },
              muteVoice: {
                label: 'Mute voice',
                type: 'radio',
                options: {
                  n: 'No',
                  y: 'Yes',
                },
              },
              muteKick: {
                label: 'Mute kick',
                type: 'radio',
                options: {
                  n: 'No',
                  y: 'Yes',
                },
              },
              muteSnare: {
                label: 'Mute snare',
                type: 'radio',
                options: {
                  n: 'No',
                  y: 'Yes',
                },
              },
              muteHighHat: {
                label: 'Mute high hat',
                type: 'radio',
                options: {
                  n: 'No',
                  y: 'Yes',
                },
              },
            }}
            initialValues={{
              duration: '60',
              tempo: (
                (graph.nodes.CLOCK.audioNode.params as any).frequency * 15
              ).toString(),
              snapTuning: 'n',
              muteVoice: 'n',
              muteKick: 'n',
              muteSnare: 'n',
              muteHighHat: 'n',
            }}
            buttonText="Download stem"
          />
          {renderActive && <BlockingOverlay error={null} />}
        </Container>
      ) : (
        <Center>
          <NetworkActivityIndicator
            pending={false}
            active={loading}
            error={!!error}
          />
        </Center>
      )}
    </Layout>
  );
};
