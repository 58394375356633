import { AudioNodeType } from './AudioNodes';

export enum ParamType {
  AudioParam,
  AudioBuffer,
  Float,
  FloatArray,
  Integer,
  Boolean,
  String,
  Rhythm,
  MIDIDevice,
}

export enum ScaleType {
  Linear,
  Log,
}

type AudioParamParamDescription = {
  type: ParamType.AudioParam;
  default: number;
  unit?: string;
  min?: number;
  max?: number;
  scale?: ScaleType;
};
type AudioBufferParamDescription = {
  type: ParamType.AudioBuffer;
  default: AudioBuffer | null;
};
type IntegerParamDescription = {
  type: ParamType.Integer;
  default: number;
  unit?: string;
  enum?: number[];
  min?: number;
  max?: number;
  scale?: ScaleType;
};
type FloatParamDescription = {
  type: ParamType.Float;
  default: number;
  unit?: string;
  min?: number;
  max?: number;
  scale?: ScaleType;
};
type FloatArrayParamDescription = {
  type: ParamType.FloatArray;
  default: number[];
};
type Float32ArrayParamDescription = {
  type: ParamType.FloatArray;
  default: Float32Array | null;
};
type BooleanParamDescription = {
  type: ParamType.Boolean;
  default: boolean;
};
type RhythmParamDescription = {
  type: ParamType.Rhythm;
  default: boolean[];
};
type StringParamDescription = {
  type: ParamType.String;
  default: string;
  enum: string[];
};
type MIDIDeviceParamDescription = {
  type: ParamType.MIDIDevice;
  default: string | null;
};

export type ParamDescription = {
  displayName: string;
  writeOnce?: boolean;
} & (
  | AudioParamParamDescription
  | AudioBufferParamDescription
  | IntegerParamDescription
  | FloatParamDescription
  | FloatArrayParamDescription
  | Float32ArrayParamDescription
  | BooleanParamDescription
  | RhythmParamDescription
  | StringParamDescription
  | MIDIDeviceParamDescription
);

export type DataDescription = { displayName: string } & (
  | Omit<AudioParamParamDescription, 'default'>
  | Omit<AudioBufferParamDescription, 'default'>
  | Omit<IntegerParamDescription, 'default'>
  | Omit<FloatParamDescription, 'default'>
  | Omit<FloatArrayParamDescription, 'default'>
  | Omit<Float32ArrayParamDescription, 'default'>
  | Omit<BooleanParamDescription, 'default'>
  | Omit<RhythmParamDescription, 'default'>
  | Omit<StringParamDescription, 'default'>
  | Omit<MIDIDeviceParamDescription, 'default'>
);

export class NodeDescription {
  displayName: string;
  numberOfInputs: number;
  numberOfInputsAndAudioParams: number;
  numberOfOutputs: number;
  params: { [param: string]: ParamDescription };
  data: { [param: string]: DataDescription };

  constructor({
    displayName,
    numberOfInputs,
    numberOfOutputs,
    params,
    data,
  }: {
    displayName: string;
    numberOfInputs: number;
    numberOfOutputs: number;
    params: { [param: string]: ParamDescription };
    data: { [param: string]: DataDescription };
  }) {
    this.displayName = displayName;
    this.numberOfInputs = numberOfInputs;
    this.numberOfOutputs = numberOfOutputs;
    this.params = params;
    this.data = data;
    this.numberOfInputsAndAudioParams =
      numberOfInputs +
      Object.values(params).reduce(
        (memo, { type }) => (type === ParamType.AudioParam ? memo + 1 : memo),
        0,
      );
  }
}

export const nodeDescriptions: {
  [type in AudioNodeType]: NodeDescription;
} = {
  [AudioNodeType.AnalyserNode]: new NodeDescription({
    displayName: 'Analyser',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      fftSize: {
        displayName: 'FFT size',
        type: ParamType.Integer,
        default: 2048,
        enum: [32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768],
        unit: 'samples',
      },
      minDecibels: {
        displayName: 'Min decibels',
        type: ParamType.Float,
        default: -100,
        unit: 'decibels',
      },
      maxDecibels: {
        displayName: 'Max decibels',
        default: -30,
        type: ParamType.Float,
        unit: 'decibels',
      },
      smoothingTimeConstant: {
        displayName: 'Smoothing time constant',
        type: ParamType.Float,
        default: 0.8,
      },
    },
    data: {
      frequencyBinCount: {
        displayName: 'Frequency bin count',
        type: ParamType.Integer,
        unit: 'bins',
      },
    },
  }),
  [AudioNodeType.AudioBufferSourceNode]: new NodeDescription({
    displayName: 'Audio buffer source',
    numberOfInputs: 0,
    numberOfOutputs: 1,
    params: {
      buffer: {
        displayName: 'Buffer',
        type: ParamType.AudioBuffer,
        default: null,
        writeOnce: true,
      },
      loop: {
        displayName: 'Loop',
        type: ParamType.Boolean,
        default: false,
      },
      loopStart: {
        displayName: 'Loop start',
        type: ParamType.Float,
        unit: 'seconds',
        default: 0,
      },
      loopEnd: {
        displayName: 'Loop end',
        type: ParamType.Float,
        unit: 'seconds',
        default: 0,
      },
      detune: {
        displayName: 'Detune',
        type: ParamType.AudioParam,
        unit: 'cents',
        default: 0,
      },
      playbackRate: {
        displayName: 'Playback rate',
        type: ParamType.AudioParam,
        default: 1,
      },
    },
    data: {},
  }),
  [AudioNodeType.AudioDestinationNode]: new NodeDescription({
    displayName: 'Destination',
    numberOfInputs: 1,
    numberOfOutputs: 0,
    params: {},
    data: {
      maxChannelCount: {
        displayName: 'Max channel count',
        type: ParamType.Integer,
        unit: 'channels',
      },
    },
  }),
  [AudioNodeType.BiquadFilterNode]: new NodeDescription({
    displayName: 'Biquad filter',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      frequency: {
        displayName: 'Frequency',
        default: 350,
        type: ParamType.AudioParam,
        unit: 'hertz',
      },
      detune: {
        displayName: 'Detune',
        type: ParamType.AudioParam,
        default: 0,
        unit: 'cents',
      },
      Q: { displayName: 'Quality', type: ParamType.AudioParam, default: 1 },
      gain: { displayName: 'Gain', type: ParamType.AudioParam, default: 0 },
      type: {
        displayName: 'Type',
        type: ParamType.String,
        default: 'lowpass',
        enum: [
          'lowpass',
          'highpass',
          'bandpass',
          'lowshelf',
          'highshelf',
          'peaking',
          'notch',
          'allpass',
        ],
      },
    },
    data: {},
  }),
  [AudioNodeType.ChannelMergerNode]: new NodeDescription({
    displayName: 'Channel merger',
    numberOfInputs: 6, // TODO: variable
    numberOfOutputs: 1,
    params: {},
    data: {},
  }),
  [AudioNodeType.ChannelSplitterNode]: new NodeDescription({
    displayName: 'Channel splitter',
    numberOfInputs: 1,
    numberOfOutputs: 6, // TODO: variable
    params: {},
    data: {},
  }),
  [AudioNodeType.ConstantSourceNode]: new NodeDescription({
    displayName: 'Constant source',
    numberOfInputs: 0,
    numberOfOutputs: 1,
    params: {
      offset: { displayName: 'Offset', type: ParamType.AudioParam, default: 1 },
    },
    data: {},
  }),
  [AudioNodeType.ConvolverNode]: new NodeDescription({
    displayName: 'Convolver',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      buffer: {
        displayName: 'Impulse response',
        type: ParamType.AudioBuffer,
        default: null,
      },
      normalize: {
        displayName: 'Normalize',
        type: ParamType.Boolean,
        default: true,
      },
    },
    data: {},
  }),
  [AudioNodeType.ADEnvelopeNode]: new NodeDescription({
    displayName: 'AD Envelope',
    numberOfInputs: 0,
    numberOfOutputs: 1,
    params: {
      clock: { displayName: 'Clock', type: ParamType.AudioParam, default: 0 },
      attack: {
        displayName: 'Attack',
        type: ParamType.AudioParam,
        unit: 'seconds',
        min: 0.001,
        default: 0.001,
      },
      decay: {
        displayName: 'Decay',
        type: ParamType.AudioParam,
        unit: 'seconds',
        min: 0.001,
        default: 0.5,
      },
      hold: {
        displayName: 'Hold',
        type: ParamType.Boolean,
        default: false,
      },
    },
    data: {},
  }),
  [AudioNodeType.DelayNode]: new NodeDescription({
    displayName: 'Delay',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      delayTime: {
        displayName: 'Time',
        type: ParamType.AudioParam,
        unit: 'seconds',
        default: 0,
      },
    },
    data: {},
  }),
  [AudioNodeType.DynamicsCompressorNode]: new NodeDescription({
    displayName: 'Dynamics compressor',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      threshold: {
        displayName: 'Threshold',
        type: ParamType.AudioParam,
        unit: 'decibels',
        default: -24,
      },
      knee: {
        displayName: 'Knee',
        type: ParamType.AudioParam,
        unit: 'decibels',
        default: 30,
      },
      ratio: { displayName: 'Ratio', type: ParamType.AudioParam, default: 12 },
      attack: {
        displayName: 'Attack',
        type: ParamType.AudioParam,
        default: 0.003,
        unit: 'seconds',
      },
      release: {
        displayName: 'Release',
        type: ParamType.AudioParam,
        default: 0.25,
        unit: 'seconds',
      },
    },
    data: {
      reduction: {
        displayName: 'Reduction',
        type: ParamType.Float,
        unit: 'decibels',
      },
    },
  }),
  [AudioNodeType.GainNode]: new NodeDescription({
    displayName: 'Gain',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      gain: { displayName: 'Gain', type: ParamType.AudioParam, default: 1 },
    },
    data: {},
  }),
  [AudioNodeType.IIRFilterNode]: new NodeDescription({
    displayName: 'IIR filter',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {},
    data: {},
  }),
  // [AudioNodeType.MIDICCNode]: new NodeDescription({
  //   displayName: 'MIDI CC',
  //   numberOfInputs: 0,
  //   numberOfOutputs: 1,
  //   params: {
  //     device: {
  //       displayName: 'Device',
  //       type: ParamType.MIDIDevice,
  //       default: null,
  //     },
  //     channel: {
  //       displayName: 'Channel',
  //       type: ParamType.Integer,
  //       default: 1,
  //       enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  //     },
  //     cc: {
  //       displayName: 'CC',
  //       type: ParamType.Integer,
  //       default: 1,
  //     },
  //   },
  //   data: {},
  // }),
  // [AudioNodeType.MIDIGateNode]: new NodeDescription({
  //   displayName: 'MIDI Gate',
  //   numberOfInputs: 0,
  //   numberOfOutputs: 1,
  //   params: {
  //     device: {
  //       displayName: 'Device',
  //       type: ParamType.MIDIDevice,
  //       default: null,
  //     },
  //     channel: {
  //       displayName: 'Channel',
  //       type: ParamType.Integer,
  //       default: 1,
  //       enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  //     },
  //     note: {
  //       displayName: 'Note',
  //       type: ParamType.Integer,
  //       default: 0,
  //     },
  //   },
  //   data: {},
  // }),
  // [AudioNodeType.MIDIInputNode]: new NodeDescription({
  //   displayName: 'MIDI Input',
  //   numberOfInputs: 0,
  //   numberOfOutputs: 3,
  //   params: {
  //     device: {
  //       displayName: 'Device',
  //       default: null,
  //       type: ParamType.MIDIDevice,
  //     },
  //     channel: {
  //       displayName: 'Channel',
  //       type: ParamType.Integer,
  //       default: 1,
  //       enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  //     },
  //     pitchBendRange: {
  //       displayName: 'Pitch bend range',
  //       type: ParamType.Float,
  //       default: 200,
  //       unit: 'cents',
  //     },
  //   },
  //   data: {},
  // }),
  [AudioNodeType.OscillatorNode]: new NodeDescription({
    displayName: 'Oscillator',
    numberOfInputs: 0,
    numberOfOutputs: 1,
    params: {
      frequency: {
        displayName: 'Frequency',
        type: ParamType.AudioParam,
        unit: 'hertz',
        default: 440,
      },
      detune: {
        displayName: 'Detune',
        type: ParamType.AudioParam,
        default: 0,
        unit: 'cents',
      },
      type: {
        displayName: 'Type',
        type: ParamType.String,
        default: 'sine',
        enum: [
          'sine',
          'square',
          'sawtooth',
          'triangle',
          // omitting 'custom' here because it can only be set via the
          // setPeriodicWave() method
          // 'custom,
        ],
      },
    },
    data: {},
  }),
  [AudioNodeType.PannerNode]: new NodeDescription({
    displayName: 'Panner',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      coneInnerAngle: {
        displayName: 'Cone inner angle',
        type: ParamType.Float,
        unit: 'degrees',
        default: 360,
      },
      coneOuterAngle: {
        displayName: 'Cone outer angle',
        type: ParamType.Float,
        unit: 'degrees',
        default: 360,
      },
      coneOuterGain: {
        displayName: 'Cone outer gain',
        type: ParamType.Float,
        default: 0,
      },
      distanceModel: {
        displayName: 'Distance model',
        type: ParamType.String,
        default: 'inverse',
        enum: ['linear', 'inverse', 'exponential'],
      },
      panningModel: {
        displayName: 'Panning model',
        type: ParamType.String,
        default: 'equalpower',
        enum: ['equalpower', 'HRTF'],
      },
      maxDistance: {
        displayName: 'Max distance',
        type: ParamType.Float,
        default: 10000,
      },
      orientationX: {
        displayName: 'Orientation X',
        type: ParamType.AudioParam,
        default: 1,
      },
      orientationY: {
        displayName: 'Orientation Y',
        type: ParamType.AudioParam,
        default: 0,
      },
      orientationZ: {
        displayName: 'Orientation Z',
        type: ParamType.AudioParam,
        default: 0,
      },
      positionX: {
        displayName: 'Position X',
        type: ParamType.AudioParam,
        default: 0,
      },
      positionY: {
        displayName: 'Position Y',
        type: ParamType.AudioParam,
        default: 0,
      },
      positionZ: {
        displayName: 'Position Z',
        type: ParamType.AudioParam,
        default: 0,
      },
      refDistance: {
        displayName: 'Reference distance',
        type: ParamType.Float,
        default: 1,
      },
      rollOffFactor: {
        displayName: 'Roll-off factor',
        type: ParamType.Float,
        default: 1,
      },
    },
    data: {},
  }),
  // [AudioNodeType.PitchShifterNode]: new NodeDescription({
  //   displayName: 'Pitch Shifter',
  //   numberOfInputs: 1,
  //   numberOfOutputs: 1,
  //   params: {
  //     detune: {
  //       displayName: 'Detune',
  //       type: ParamType.AudioParam,
  //       default: 0,
  //       unit: 'cents',
  //     },
  //   },
  //   data: {},
  // }),
  [AudioNodeType.QuantizerNode]: new NodeDescription({
    displayName: 'Quantizer',
    numberOfInputs: 0,
    numberOfOutputs: 2,
    params: {
      signal: {
        displayName: 'Signal',
        type: ParamType.AudioParam,
        default: 0,
      },
      values: {
        displayName: 'Values',
        type: ParamType.FloatArray,
        default: [0],
      },
      range: {
        displayName: 'Range',
        type: ParamType.Float,
        min: 0,
        default: 100,
      },
    },
    data: {},
  }),
  // [AudioNodeType.RandomGateNode]: new NodeDescription({
  //   displayName: 'Random gate',
  //   numberOfInputs: 0,
  //   numberOfOutputs: 1,
  //   params: {
  //     clock: { displayName: 'Clock', type: ParamType.AudioParam, default: 0 },
  //     probability: {
  //       displayName: 'Probability',
  //       type: ParamType.AudioParam,
  //       default: 0.5,
  //     },
  //   },
  //   data: {},
  // }),
  // [AudioNodeType.ResonatorNode]: new NodeDescription({
  //   displayName: 'Resonator',
  //   numberOfInputs: 1,
  //   numberOfOutputs: 1,
  //   params: {
  //     frequency: {
  //       displayName: 'Frequency',
  //       type: ParamType.AudioParam,
  //       default: 220,
  //       unit: 'hertz',
  //     },
  //     detune: {
  //       displayName: 'Detune',
  //       type: ParamType.AudioParam,
  //       unit: 'cents',
  //       default: 0,
  //     },
  //     structure: {
  //       displayName: 'Structure',
  //       type: ParamType.AudioParam,
  //       default: 0.25,
  //     },
  //     brightness: {
  //       displayName: 'Brightness',
  //       type: ParamType.AudioParam,
  //       default: 0.5,
  //     },
  //     damping: {
  //       displayName: 'Damping',
  //       type: ParamType.AudioParam,
  //       default: 0.3,
  //     },
  //     position: {
  //       displayName: 'Position',
  //       type: ParamType.AudioParam,
  //       default: 0.999,
  //     },
  //   },
  //   data: {},
  // }),
  [AudioNodeType.ReverbNode]: new NodeDescription({
    displayName: 'Reverb',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      diffusion: {
        displayName: 'Diffusion',
        type: ParamType.AudioParam,
        default: 0.625,
        unit: 'cents',
      },
      lp: {
        displayName: 'Lowpass',
        type: ParamType.AudioParam,
        default: 0.7,
      },
      reverbTime: {
        displayName: 'Reverb time',
        type: ParamType.AudioParam,
        default: 0.5,
      },
      amount: {
        displayName: 'Amount',
        type: ParamType.AudioParam,
        default: 0.5,
      },
      gain: {
        displayName: 'Gain',
        type: ParamType.AudioParam,
        default: 0.5,
      },
    },
    data: {},
  }),
  [AudioNodeType.RhythmNode]: new NodeDescription({
    displayName: 'Rhythm',
    numberOfInputs: 0,
    numberOfOutputs: 1,
    params: {
      clock: { displayName: 'Clock', type: ParamType.AudioParam, default: 0 },
      reset: { displayName: 'Reset', type: ParamType.AudioParam, default: 0 },
      sequence: {
        displayName: 'Sequence',
        type: ParamType.Rhythm,
        default: [],
      },
    },
    data: {},
  }),
  [AudioNodeType.SampleAndHoldNode]: new NodeDescription({
    displayName: 'Sample and hold',
    numberOfInputs: 0,
    numberOfOutputs: 1,
    params: {
      clock: { displayName: 'Clock', type: ParamType.AudioParam, default: 0 },
      signal: { displayName: 'Signal', type: ParamType.AudioParam, default: 0 },
    },
    data: {},
  }),
  [AudioNodeType.SequenceNode]: new NodeDescription({
    displayName: 'Sequence',
    numberOfInputs: 0,
    numberOfOutputs: 1,
    params: {
      clock: { displayName: 'Clock', type: ParamType.AudioParam, default: 0 },
      reset: { displayName: 'Reset', type: ParamType.AudioParam, default: 0 },
      sequence: {
        displayName: 'Sequence',
        type: ParamType.FloatArray,
        default: [],
      },
    },
    data: {},
  }),
  [AudioNodeType.StereoPannerNode]: new NodeDescription({
    displayName: 'Stereo panner',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      pan: {
        displayName: 'Pan',
        type: ParamType.AudioParam,
        min: -1,
        max: 1,
        default: 0,
      },
    },
    data: {},
  }),
  [AudioNodeType.TriggeredSamplerNode]: new NodeDescription({
    displayName: 'Triggered sampler',
    numberOfInputs: 0,
    numberOfOutputs: 1,
    params: {
      buffer: {
        displayName: 'Buffer',
        type: ParamType.AudioBuffer,
        default: null,
      },
      clock: { displayName: 'Clock', type: ParamType.AudioParam, default: 0 },
      detune: {
        displayName: 'Detune',
        type: ParamType.AudioParam,
        unit: 'cents',
        default: 0,
      },
    },
    data: {},
  }),
  [AudioNodeType.WaveShaperNode]: new NodeDescription({
    displayName: 'Wave shaper',
    numberOfInputs: 1,
    numberOfOutputs: 1,
    params: {
      curve: {
        displayName: 'Curve',
        type: ParamType.FloatArray,
        default: null,
      },
      oversample: {
        displayName: 'Oversample',
        type: ParamType.String,
        default: 'none',
        enum: ['none', '2x', '4x'],
      },
    },
    data: {},
  }),
  // [AudioNodeType.WhiteNoiseNode]: new NodeDescription({
  //   displayName: 'White noise',
  //   numberOfInputs: 0,
  //   numberOfOutputs: 1,
  //   params: {},
  //   data: {},
  // }),
};
