import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import {
  AlbumCover,
  Button,
  GridLayout,
  Layout,
  Link,
  NetworkActivityIndicator,
  P,
  Stack,
  Center,
} from '../../components';
import { TAB_ORDER } from '../../utils';
import { lineHeight } from '../../style';
import { useAPIData } from '../../hooks';
import { Metadata } from '../../types';
import { useLocalState } from '../../hooks/useLocalStorage';
import { randomGraph } from '../../randomGraph';

const Container = styled.div`
  padding: ${lineHeight}px ${2 * lineHeight}px ${8 * lineHeight}px;
  overflow-y: auto;
`;

export type GalleryProps = {};

export const Gallery: FC<GalleryProps> = () => {
  const [page, updatePage] = useLocalState(1, 'AG_GALLERY_PAGE');
  const container = useRef(null);

  let [loading, error, data] = useAPIData<{
    count: number;
    numberOfPages: number;
    results: Metadata[];
  }>(`audioglyphs/gallery?page=${page}`, [page]);

  const onPaginate = (num: number) => () => {
    // @ts-ignore
    container.current?.scrollTo(0, 0);
    updatePage((p: number) => Number(p) + Number(num));
  };

  return (
    <Layout
      title="Audioglyphs - gallery"
      breadcrumbs={
        <>
          <Link plain href="/" tabIndex={TAB_ORDER.HEADER_BUTTON}>
            audioglyphs
          </Link>
          {' / '}
          <Link plain href={`/gallery`} tabIndex={TAB_ORDER.HEADER_BUTTON}>
            gallery
          </Link>
        </>
      }
    >
      {data ? (
        <Container ref={container}>
          <GridLayout minSize={240}>
            {data.results?.map(glyph => {
              const graph = randomGraph(glyph.seed);
              return (
                <AlbumCover key={graph.seed} metadata={glyph} graph={graph} />
              );
            })}
            <Stack>
              <Button
                disabled={!(data && data.numberOfPages > 1 && page > 1)}
                onClick={onPaginate(-1)}
              >
                Previous Page
              </Button>
              <Button
                disabled={!(data && data.numberOfPages > page)}
                onClick={onPaginate(1)}
              >
                Next Page
              </Button>
              <P>
                Page {page} / {data?.numberOfPages ?? '--'}
              </P>
            </Stack>
          </GridLayout>
        </Container>
      ) : (
        <Center>
          <NetworkActivityIndicator
            pending={false}
            active={loading}
            error={!!error}
          />
        </Center>
      )}
    </Layout>
  );
};
