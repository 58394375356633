import styled from 'styled-components';
import { maxBlockContentWidth } from '../style';

export const H1 = styled.h1`
  font-weight: 600;
  font-family: 'Display', sans-serif;
  font-size: 4em;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  color: var(--ui-control-color);
  user-select: text;
  @media only screen and (max-width: ${maxBlockContentWidth}px) {
    font-size: 2.5em;
  }
`;
