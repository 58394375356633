import React, { FC } from 'react';
import styled from 'styled-components';
import { lineHeight } from '../../style';
import { ProgressAnimation } from './ProgressAnimation';

const Container = styled.div`
  padding: ${2 * lineHeight}px 0;
`;

const Row = styled.div`
  margin-top: ${lineHeight}px;
  display: flex;
  flex-wrap: wrap;
  gap: ${lineHeight}px;
  justify-content: space-between;
  align-items: center;
`;

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${lineHeight / 2}px;
`;

const Pill = styled.button`
  border-radius: ${0.75 * lineHeight}px;
  padding: ${0.25 * lineHeight}px ${0.75 * lineHeight}px;
  background-color: var(
    ${({ selected }: { selected: boolean }) =>
      selected ? '--node-color' : '--project-preview-color'}
  );
  border: none;
  color: var(
    ${({ selected }: { selected: boolean }) =>
      selected ? '--text-color' : '--text-secondary-color'}
  );
  &:hover {
    background-color: var(
      ${({ selected }: { selected: boolean }) =>
        selected ? '--node-color' : '--background-secondary-color'}
    );
  }
`;

export type PlaybackControlsProps = {
  duration: number;
  setDuration: (duration: number) => void;
  shuffle: boolean;
  setShuffle: (shuffle: boolean) => void;
  autoAdvanceStartOffset: number;
  playing: boolean;
};

export const PlaybackControls: FC<PlaybackControlsProps> = ({
  duration,
  setDuration,
  shuffle,
  setShuffle,
  autoAdvanceStartOffset,
  playing,
}) => {
  return (
    <Container>
      <ProgressAnimation
        autoAdvanceStartOffset={autoAdvanceStartOffset}
        duration={duration}
        playing={playing}
      />
      <Row>
        <Group>
          {[Infinity, 0.25, 0.5, 1, 2, 4, 8].map(n => (
            <Pill
              key={n}
              selected={duration === n}
              onClick={() => setDuration(n)}
            >
              {n === Infinity ? '∞' : n === 0.25 ? '¼' : n === 0.5 ? '½' : n}
            </Pill>
          ))}
        </Group>
        <Group>
          <Pill selected={shuffle} onClick={() => setShuffle(true)}>
            Rnd
          </Pill>
          <Pill selected={!shuffle} onClick={() => setShuffle(false)}>
            Lin
          </Pill>
        </Group>
      </Row>
    </Container>
  );
};
