import React, { FC } from 'react';
import { lineHeight } from '../style';

export type GlyphProps = {
  matrix: number[][];
  size?: number;
};

const countNeighbors = (matrix: number[][], x: number, y: number): number => {
  return (
    (matrix[y - 1]?.[x] || 0) +
    (matrix[y + 1]?.[x] || 0) +
    (matrix[y][x - 1] || 0) +
    (matrix[y][x + 1] || 0)
  );
};

export const Glyph: FC<GlyphProps> = ({ matrix, size = 1.25 * lineHeight }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 1 1">
      <g shapeRendering="crispEdges">
        {matrix.map((row, y) =>
          row.map((v, x) => {
            const color = v
              ? null
              : countNeighbors(matrix, x, y)
              ? 'var(--text-color)'
              : null;
            return color ? (
              <>
                <rect
                  x={x / 10}
                  y={y / 10}
                  width={0.1}
                  height={0.1}
                  fill={color}
                />
                <rect
                  x={0.9 - x / 10}
                  y={y / 10}
                  width={0.1}
                  height={0.1}
                  fill={color}
                />
              </>
            ) : null;
          }),
        )}
      </g>
    </svg>
  );
};
