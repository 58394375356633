import React, { ChangeEvent, FC, MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { Dialog, Label, Input, RadioButtonGroup } from '../../components';
import WEB_URL from '../../data/WEB_URL';
import { lineHeight, inputWidth } from '../../style';
import { TAB_ORDER } from '../../utils';

export type EmbedDialogProps = {
  close: () => void;
  id: number;
};

const Field = styled.div`
  margin: ${lineHeight}px 0;
`;

const Code = styled.div`
  margin: ${2 * lineHeight}px 0 0;
  padding: ${lineHeight / 2}px;
  width: ${inputWidth}px;
  background: var(--visualization-color);
  word-wrap: break-word;
  user-select: auto;
`;

const Row = styled.div`
  margin-top: ${lineHeight}px;
  text-align: center;
  width: ${inputWidth}px;
`;

const intPattern = /^[0-9]*$/;

export const EmbedDialog: FC<EmbedDialogProps> = ({ close, id }) => {
  const [width, setWidth] = useState(360);
  const [includeVisualization, setIncludeVisualization] = useState<'y' | 'n'>(
    'y',
  );

  const w = Math.max(240, width);
  const h = includeVisualization ? w + 45 : 45;
  const embedCode = `<iframe width="${w}" height="${h}" scrolling="no" frameborder="no" src="${WEB_URL}/embed?id=${id}&v=${includeVisualization}"></iframe>`;

  return (
    <Dialog title="Embed player" close={close}>
      <Field>
        <Label htmlFor="width">Width (pixels, min 240)</Label>
        <Input
          name="width"
          id="width"
          tabIndex={TAB_ORDER.INPUT}
          value={width}
          onChange={e => {
            if (e.target.value.match(intPattern)) {
              setWidth(parseInt(e.target.value));
            }
          }}
        />
      </Field>
      <Field>
        <Label htmlFor="visualization">Include visualization</Label>
        <RadioButtonGroup
          name="visualization"
          value={includeVisualization}
          options={{
            y: 'Yes',
            n: 'No',
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setIncludeVisualization(e.target.value as 'y' | 'n');
          }}
        />
      </Field>
      <Code
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          // select text
          const selection = window.getSelection();
          const range = document.createRange();
          range.selectNodeContents(e.target as HTMLDivElement);
          selection?.removeAllRanges();
          selection?.addRange(range);

          // write to clipboard
          navigator.clipboard.writeText(embedCode);
        }}
        tabIndex={TAB_ORDER.INPUT}
      >
        {embedCode}
      </Code>
      <Row>Click embed code to copy</Row>
    </Dialog>
  );
};
