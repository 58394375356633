export type Vector = [number, number];

export const multiply = ([x1, y1]: Vector, [x2, y2]: Vector): Vector => [
  x1 * x2,
  y1 * y2,
];

export const multiplyScalar = ([x, y]: Vector, n: number): Vector => [
  x * n,
  y * n,
];

export const divide = ([x1, y1]: Vector, [x2, y2]: Vector): Vector => [
  x1 / x2,
  y1 / y2,
];

export const divideScalar = ([x, y]: Vector, n: number): Vector => [
  x / n,
  y / n,
];

export const add = ([x1, y1]: Vector, [x2, y2]: Vector): Vector => [
  x1 + x2,
  y1 + y2,
];

export const subtract = ([x1, y1]: Vector, [x2, y2]: Vector): Vector => [
  x1 - x2,
  y1 - y2,
];

export const modulus = ([x1, y1]: Vector, [x2, y2]: Vector): Vector => [
  x1 % x2,
  y1 % y2,
];

export const max = ([x1, y1]: Vector, [x2, y2]: Vector): Vector => [
  Math.max(x1, x2),
  Math.max(y1, y2),
];

export const min = ([x1, y1]: Vector, [x2, y2]: Vector): Vector => [
  Math.min(x1, x2),
  Math.min(y1, y2),
];

export const fit = ([x1, y1]: Vector, [x2, y2]: Vector): Vector =>
  x1 / x2 > y1 / y2 ? [(x2 * y1) / y2, y1] : [x1, (y2 * x1) / x2];

export const constrain = ([x1, y1]: Vector, [x2, y2]: Vector): Vector =>
  x1 < x2 && y1 < y2
    ? [x1, y1]
    : x1 / x2 > y1 / y2
    ? [x2, x2 * (y1 / x1)]
    : [y2 * (x1 / y1), y2];

export const floor = ([x, y]: Vector): Vector => [Math.floor(x), Math.floor(y)];

export const round = ([x, y]: Vector): Vector => [Math.round(x), Math.round(y)];

export const magnitude = ([x, y]: Vector): number =>
  Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

export const equals = ([x1, y1]: Vector, [x2, y2]: Vector): boolean =>
  x1 === x2 && y1 === y2;

export const growVectorToMatchAspectRatio = (
  [x1, y1]: Vector,
  [x2, y2]: Vector,
): Vector => {
  return x1 / y1 < x2 / y2 ? [(x2 / y2) * y1, y1] : [x1, (y2 / x2) * x1];
};
