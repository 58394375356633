import { TriggerEvent } from '../audioNodeUtils';

export class SampleAndHoldNode extends AudioWorkletNode {
  readonly signal: AudioParam;
  readonly clock: AudioParam;

  constructor(context: BaseAudioContext) {
    super(context, 'SampleAndHoldProcessor', {
      numberOfInputs: 0,
      numberOfOutputs: 1,
      outputChannelCount: [1],
      channelCount: 1,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
    });
    this.port.onmessage = () => {
      this.dispatchEvent(new TriggerEvent());
    };

    // @ts-ignore
    this.signal = this.parameters.get('signal');
    // @ts-ignore
    this.clock = this.parameters.get('clock');
  }
}
