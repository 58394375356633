import { Node, Vector, nodeDescriptions, AudioNodeType } from '../types';

export const getNodeSize = (node: Node): Vector => {
  return getDefaultNodeSize(node.audioNode.type);
};

export const getDefaultNodeSize = (type: AudioNodeType): Vector => {
  const description = nodeDescriptions[type];
  return [
    3,
    Math.max(
      description.numberOfInputsAndAudioParams,
      description.numberOfOutputs,
      3,
    ),
  ];
};
