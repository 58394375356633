export class ReverbNode extends AudioWorkletNode {
  readonly diffusion: AudioParam;
  readonly lp: AudioParam;
  readonly reverbTime: AudioParam;
  readonly amount: AudioParam;
  readonly gain: AudioParam;

  constructor(
    context: BaseAudioContext,
    {
      diffusion = 0.625,
      lp = 0.7,
      reverbTime = 0.5,
      amount = 0.5,
      gain = 0,
    }: {
      diffusion?: number;
      lp?: number;
      reverbTime?: number;
      amount?: number;
      gain?: number;
    },
  ) {
    super(context, 'ReverbProcessor', {
      numberOfInputs: 1,
      numberOfOutputs: 1,
      outputChannelCount: [2],
      channelCount: 2,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
      parameterData: {
        diffusion,
        lp,
        reverbTime,
        amount,
        gain,
      },
    });

    // @ts-ignore
    this.diffusion = this.parameters.get('diffusion');
    // @ts-ignore
    this.lp = this.parameters.get('lp');
    // @ts-ignore
    this.reverbTime = this.parameters.get('reverbTime');
    // @ts-ignore
    this.amount = this.parameters.get('amount');
    // @ts-ignore
    this.gain = this.parameters.get('gain');
  }
}
