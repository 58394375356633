import REQUEST_URL from '../data/REQUEST_URL';
import { Response } from '../types';

export const request = async <ResponseData, RequestData = any>(
  path: string,
  method: string,
  requestBody: RequestData,
): Promise<Response<ResponseData>> => {
  const response = await fetch(`${REQUEST_URL}/${path}`, {
    method,
    body: JSON.stringify(requestBody),
    mode: 'cors',
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { status } = response;
  let body = null;
  let error = null;
  try {
    if (status === 200) {
      body = await response.json();
    } else if (status >= 400) {
      ({ error } = await response.json());
    }
  } catch (e) {
    error = e.message;
  }

  return {
    status,
    body,
    error,
  };
};
