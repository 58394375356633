import styled from 'styled-components';

export type AProps = {
  block?: boolean;
  plain?: boolean;
};

export const A = styled.a`
  display: ${({ block }: AProps) => (block ? 'block' : 'inline')};
  cursor: pointer;
  text-decoration: ${({ plain }: AProps) => (plain ? 'none' : 'underline')};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  user-select: text;
  &:hover {
    color: var(--text-secondary-color);
  }
`;
