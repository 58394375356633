import React, { FC, ReactNode, useRef, useEffect } from 'react';

export type FocusLoopProps = {
  children?: ReactNode;
  spliceID?: string;
  spliceElement?: HTMLElement | SVGElement | null;
  focusFirst?: boolean;
};

export const FocusLoop: FC<FocusLoopProps> = ({
  children,
  spliceID,
  spliceElement,
  focusFirst,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const container = ref.current;
    const splice = spliceElement
      ? spliceElement
      : spliceID !== undefined
      ? (document.getElementById(spliceID) as HTMLElement | SVGElement | null)
      : null;

    if (container == null) return;
    const originalActiveElement = document.activeElement;
    if (focusFirst) {
      ([...container.querySelectorAll('[tabindex]')].reduce(
        (memo, v) => (memo && memo.tabIndex <= v.tabIndex ? memo : v),
        null as null | Element,
      ) as Element).focus();
    } else {
      (splice || container).focus();
    }

    const keydown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        const elements = ([
          ...container.querySelectorAll('[tabindex]'),
        ] as HTMLElement[]).filter(e => e.tabIndex >= 0);
        elements.sort((a, b) =>
          a.tabIndex > b.tabIndex ? 1 : a.tabIndex === b.tabIndex ? 0 : -1,
        );
        const activeElement = document.activeElement;
        const firstFocusable = elements[0];
        const lastFocusable = elements[elements.length - 1];

        if (e.shiftKey) {
          /* shift + tab */
          if (activeElement === splice || activeElement === container) {
            lastFocusable.focus();
            e.preventDefault();
          } else if (activeElement === firstFocusable) {
            (splice || lastFocusable).focus();
            e.preventDefault();
          }
        } else {
          /* tab */
          if (activeElement === lastFocusable) {
            (splice || firstFocusable).focus();
            e.preventDefault();
          } else if (activeElement === splice || activeElement === container) {
            firstFocusable.focus();
            e.preventDefault();
          }
        }
      }
    };

    container.addEventListener('keydown', keydown);
    (splice as HTMLElement)?.addEventListener('keydown', keydown);

    return () => {
      container.removeEventListener('keydown', keydown);
      (splice as HTMLElement)?.removeEventListener('keydown', keydown);

      (splice || (originalActiveElement as HTMLOrSVGElement | null))?.focus();
    };
  }, [spliceID, spliceElement]);

  return (
    <div ref={ref} tabIndex={-1} style={{ outline: 'none' }}>
      {children}
    </div>
  );
};
