import styled from 'styled-components';
import { lineHeight } from '../style';

export type GridLayoutProps = {
  space?: number;
  minSize?: number;
};

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      ${({ minSize = 15 * lineHeight }: GridLayoutProps) => minSize}px,
      1fr
    )
  );
  gap: ${({ space = 1.5 * lineHeight }: GridLayoutProps) => space}px;
`;
