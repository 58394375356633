import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { hsluvToHex } from 'hsluv-ts';
import { Vector } from '../../../types';

const SVG = styled.svg`
  display: flex;
  background-color: var(--grid-color);
`;

type Color = [number, number, number];

type LinePatternProps = {
  type: 'line';
  count: number;
  angle?: number;
  exponent?: number;
  scale?: number;
  translate?: Vector;
  color1: Color;
  color2?: Color;
  children?: ReactNode;
};

type CirclePatternProps = {
  type: 'circle';
  count: number;
  exponent?: number;
  scale?: number;
  translate?: Vector;
  color1: Color;
  color2?: Color;
  children?: ReactNode;
};

type PatternProps = LinePatternProps | CirclePatternProps;

const LinePattern: FC<LinePatternProps> = ({
  count,
  angle = 0,
  exponent = 1,
  scale = Math.sqrt(2),
  translate = [(1 - scale) / 2, (1 - scale) / 2],
  color1 = [0, 100, 50],
  color2 = color1,
  children,
}) => {
  return (
    <g
      transform={`translate(${translate.join(
        ',',
      )}) scale(${scale}) rotate(${angle}, 0.5, 0.5)`}
    >
      <g>
        {new Array(count).fill(undefined).map((_, i) => {
          const x = Math.pow(i / count, exponent);
          const color = hsluvToHex(
            color1.map((v, i) => x * v + (1 - x) * color2[i]) as Color,
          );
          return (
            <line
              strokeWidth={0.004 / scale}
              x1={x}
              x2={x}
              y1={0}
              y2={1}
              stroke={color}
            />
          );
        })}
        {children}
      </g>
    </g>
  );
};

const CirclePattern: FC<CirclePatternProps> = ({
  count,
  exponent = 1,
  scale = Math.sqrt(2),
  translate = [(1 - scale) / 2, (1 - scale) / 2],
  color1 = [0, 100, 50],
  color2 = color1,
  children,
}) => {
  return (
    <g transform={`translate(${translate.join(',')}) scale(${scale})`}>
      <g>
        {new Array(count).fill(undefined).map((_, i) => {
          const d = Math.pow(i / count, exponent);
          const r = d / 2;
          const color = hsluvToHex(
            color1.map((v, i) => d * v + (1 - d) * color2[i]) as Color,
          );
          return (
            <circle
              strokeWidth={0.004 / scale}
              cx={0.5}
              cy={0.5}
              r={r}
              stroke={color}
              fill="none"
            />
          );
        })}
        {children}
      </g>
    </g>
  );
};

export type MoireProps = {
  patterns: PatternProps[];
};

export const Moire: FC<MoireProps> = ({ patterns }) => {
  return (
    <SVG viewBox="0 0 1 1" width={360} height={360}>
      {patterns.map((pattern, i) =>
        pattern.type === 'line' ? (
          <LinePattern {...pattern} key={i} />
        ) : (
          <CirclePattern {...pattern} key={i} />
        ),
      )}
    </SVG>
  );
};
