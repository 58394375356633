import { Graph } from './types';
import { baseGraph } from './baseGraph';
import { randomName } from './utils/randomName';
import { mulberry32 } from './utils/mulberry32';

type Override = [nodeId: string, paramName: string, value: any];

const applyOverrides = (graph: Graph, overrides: Override[]): Graph => {
  return {
    ...graph,
    nodes: overrides.reduce(
      (memo, [nodeId, param, value]) => ({
        ...memo,
        [nodeId]: {
          ...memo[nodeId],
          audioNode: {
            ...memo[nodeId].audioNode,
            params: {
              ...memo[nodeId].audioNode.params,
              [param]: value,
            },
          } as any,
        },
      }),
      graph.nodes,
    ),
  };
};

export const randomGraph = (seed: number): Graph => {
  const rand = mulberry32(seed);

  const name = randomName(Math.floor(6 + rand() * 4), rand);

  const random = (min: number, max: number, pow: number = 1) =>
    min + Math.pow(rand(), pow) * (max - min);
  const randomMember = (items: any[]) =>
    items[Math.floor(rand() * items.length)];
  const randomSubset = <T>(items: [probability: number, value: T][]): T[] =>
    items.reduce(
      (memo, [p, v]) => (p > rand() ? [...memo, v] : memo),
      [] as T[],
    );
  const randomSequence = (
    minLength: number,
    maxLength: number,
    min: number,
    max: number,
    pow: number = 1,
  ): number[] =>
    new Array(Math.floor(minLength + rand() * (maxLength - minLength)))
      .fill(0)
      .map(() => random(min, max, pow));
  const randomRhythm = (
    minLength: number,
    maxLength: number,
    probabilities: number[],
  ): number[] =>
    new Array(Math.floor(minLength + rand() * (maxLength - minLength)))
      .fill(0)
      .map((_, i) => (probabilities[i] > rand() ? 1 : 0));

  const bdOn = rand() > 0.4;
  const snrOn = bdOn && rand() > 0.3;

  return {
    ...applyOverrides(baseGraph, [
      ['CLOCK', 'frequency', random(4, 10, 1.1)],
      ['PITCH', 'frequency', random(1, 1000)],
      ['PITCH', 'type', randomMember(['sine', 'sawtooth', 'triangle'])],
      ['PITCH_MOD', 'frequency', random(0, 1, 2)],
      ['PITCH_MOD', 'type', randomMember(['sine', 'sawtooth', 'triangle'])],
      ['PITCH_MOD_AMT', 'gain', random(0, 2, 3)],
      ['RNGE', 'gain', random(600, 2400)],
      ['OSC', 'frequency', random(220, 440)],
      ['OSC', 'type', randomMember(['square', 'sawtooth', 'triangle'])],
      ['AD', 'attack', random(0.01, 0.1, 2)],
      ['AD', 'decay', random(0.1, 0.5)],
      ['RVB', 'diffusion', random(0, 1)],
      ['RVB', 'lp', random(0.5, 1)],
      ['RVB', 'reverbTime', random(0, 0.9, 1.2)],
      ['RVB', 'amount', random(0.1, 0.5, 1.5)],
      ['FLT', 'frequency', random(440, 1760)],
      ['FLT', 'Q', random(1, 9, 3)],
      ['FLT_LFO', 'frequency', random(0, 0.5, 2)],
      ['FLT_LFO_AMT', 'gain', random(0, 1200)],
      [
        'QNT',
        'values',
        rand() > 0.999
          ? randomSubset([
              [1, 0],
              [0.8, 171],
              [0.8, 343],
              [0.8, 514],
              [0.8, 686],
              [0.8, 857],
              [0.8, 1029],
            ])
          : rand() > 0.3
          ? randomSubset([
              [1, 0],
              [0.3, 200],
              [0.7, 300],
              [0.6, 500],
              [0.7, 700],
              [0.4, 800],
              [0.5, 1000],
            ])
          : randomSubset([
              [1, 0],
              [0.5, 200],
              [0.7, 400],
              [0.6, 500],
              [0.7, 700],
              [0.7, 900],
              [0.2, 1100],
            ]),
      ],
      [
        'HH_LVL',
        'sequence',
        randomSequence(3, 17, 0.06, random(0.06, 0.12), random(1, 2)),
      ],
      ['HH_SMP', 'detune', random(0, 2400)],
      [
        'HH_RTM',
        'sequence',
        randomRhythm(
          4,
          7,
          rand() > 0.5 ? [0.5, 0.5, 0.5, 0.5, 0.5, 0, 0] : [0, 0, 0, 0, 0, 0],
        ),
      ],
      ['HH_FLT', 'frequency', random(1760, 7040)],
      ['BD_GAN', 'gain', bdOn ? random(0.5, 0.8) : 0],
      [
        'BD_RTM',
        'sequence',
        !bdOn
          ? [0]
          : rand() > 0.5
          ? randomRhythm(8, 8, [1, 0, 0, 0.5, 0.5, 0.6, 0, 0])
          : randomRhythm(16, 16, [
              1,
              0.2,
              0.2,
              0.7,
              0.2,
              0.2,
              0.7,
              0.2,
              0.2,
              0.7,
              0.2,
              0.2,
              0.7,
              0.2,
              0.2,
              0.2,
            ]),
      ],
      ['BD_SMP', 'detune', random(0, 1200)],
      ['SNR_GAN', 'gain', snrOn ? random(0.3, 0.8) : 0],
      [
        'SNR_RTM',
        'sequence',
        !snrOn
          ? [0]
          : rand() > 0.99
          ? randomRhythm(3, 7, [0, 0.1, 0.2, 0.1, 0.2, 0.1, 0.2])
          : rand() > 0.5
          ? [0, 0, 1, 0]
          : randomRhythm(8, 8, [0, 0, 1, 0.1, 0.1, 0.2, 0.6, 0.1]),
      ],
      ['SNR_SMP', 'detune', random(-1200, 1200)],
      ['SNR_FLT', 'frequency', random(220, 880, 1.5)],
    ]),
    seed,
    name,
  };
};
