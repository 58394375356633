import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Link } from './Link';
import { Illustration } from './Illustration';
import { PlayButton } from './PlayButton';
import { Glyph } from './Glyph';
import { Graph, Metadata } from '../types';
import { AudioGraphContext } from '../contexts';
import { lineHeight, strokeWidth } from '../style';

const CellLink = styled(Link)`
  display: block;
  &:hover {
    outline: solid var(--outline-color) ${strokeWidth}px;
    outline-offset: ${strokeWidth}px;
  }
`;

const Col = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
`;

const Row = styled.div`
  margin-top: ${lineHeight / 2}px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowSide = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const Subtext = styled.div`
  color: var(--text-secondary-color);
`;

export type AlbumCoverProps = {
  graph: Graph;
  metadata: Metadata;
  width?: number;
  visualization?: boolean;
  matrix?: number[][];
};

export const AlbumCover: FC<AlbumCoverProps> = ({
  graph,
  metadata,
  width,
  visualization = true,
}) => {
  const { audioGraph, play, pause, playing } = useContext(AudioGraphContext);
  const graphIsPlaying = playing && audioGraph?.graph.seed === graph.seed;

  return (
    <Col style={{ width }}>
      {visualization && (
        <CellLink href={`/gallery/${metadata.tokenId}`}>
          <Illustration
            key={graph.seed}
            graph={graph}
            audioGraph={audioGraph}
            playing={graphIsPlaying}
          />
        </CellLink>
      )}
      <Row>
        <RowSide>
          <PlayButton
            size={lineHeight}
            playing={graphIsPlaying}
            toggle={graphIsPlaying ? pause : () => play(graph, metadata)}
          />
          <div>
            <Link href={`/gallery/${metadata.tokenId}`}>{graph.name}</Link>
            <Subtext>#{metadata.tokenId}</Subtext>
          </div>
        </RowSide>
        <RowSide>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.pixelglyphs.io/glyph/${metadata.tokenId}`}
          >
            <Glyph size={lineHeight} matrix={metadata.matrix} />
          </a>
        </RowSide>
      </Row>
    </Col>
  );
};
