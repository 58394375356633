import styled from 'styled-components';
import { inputWidth, lineHeight } from '../style';

export const Button = styled.button`
  display: block;
  width: ${inputWidth}px;
  color: var(--button-text-color);
  background: var(--button-color);
  border: none;
  padding: ${0.25 * lineHeight}px ${0.5 * lineHeight}px;
  text-align: left;
  hyphens: auto;
  overflow-wrap: anywhere;
  position: relative;

  &:hover,
  &:active {
    background-color: var(--button-hover-color);
    color: var(--button-hover-text-color);
  }

  &:disabled {
    background-color: var(--button-disabled-color);
    color: var(--button-disabled-text-color);
  }
`;

export const PrimaryButton = styled(Button)`
  background: var(--input-color);
  color: var(--text-color);
  font-size: 13px;
  padding: ${0.5 * lineHeight}px ${1 * lineHeight}px;
`;
