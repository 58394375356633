import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { hsluvToHex } from 'hsluv-ts';
import { Graph } from '../types';
import { mulberry32 } from '../utils';
import { AudioGraph } from '../audioGraph';
import { TriggerEvent } from '../audioNodeUtils';

export type IllustrationProps = {
  graph: Graph;
  audioGraph?: AudioGraph | null;
  playing?: boolean;
  size?: number;
};

export const SVG = styled.svg`
  width: 100%;
  height: auto;
`;

export const Illustration: FC<IllustrationProps> = ({
  graph,
  audioGraph,
  playing,
  size,
}) => {
  const nodes = graph.nodes as any;

  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const triggerNodes: AudioNode[] = [];

    if (audioGraph?.graph.seed === graph.seed) {
      if (nodes.BD_GAN.audioNode.params.gain > 0)
        triggerNodes.push(audioGraph.audioNodes.BD_RTM);
      if (nodes.SNR_GAN.audioNode.params.gain > 0)
        triggerNodes.push(audioGraph.audioNodes.SNR_RTM);
      triggerNodes.push(audioGraph.audioNodes.AD);
      triggerNodes.push(audioGraph.audioNodes.HH_RTM);

      const listeners = triggerNodes.map((node, i) => {
        const listener = (e: TriggerEvent) => {
          if (e.detail) {
            (svgRef.current?.querySelector(
              `[data-id=a${i}]`,
            ) as any)?.beginElement();
            (svgRef.current?.querySelector(
              `[data-id=b${i}]`,
            ) as any)?.beginElement();
          }
        };
        node.addEventListener('trigger', listener as EventListener);
        return listener;
      });

      return () => {
        listeners.forEach((listener, i) =>
          triggerNodes[i].removeEventListener(
            'trigger',
            listener as EventListener,
          ),
        );
      };
    }
    return undefined;
  }, [graph, audioGraph, svgRef]);

  if (Object.keys(nodes).length === 0) return null;

  const dist = 0.06;
  const maxCount = 40;
  const count1 = Math.floor(
    Math.min(
      Math.max(
        ((nodes.PITCH.audioNode.params.frequency - 1) / 999) * maxCount,
        3,
      ),
      50,
    ),
  );
  const count2 = Math.floor(
    Math.min(
      Math.max(
        ((nodes.CLOCK.audioNode.params.frequency - 4) / 6) * maxCount,
        2,
      ),
      50,
    ),
  );
  const mod = nodes.PITCH_MOD.audioNode.params.frequency / 2;
  const modAmt = dist * nodes.PITCH_MOD_AMT.audioNode.params.gain;
  const mod2 = nodes.FLT_LFO.audioNode.params.frequency / 2;
  const mod2Amt = (dist * nodes.FLT_LFO_AMT.audioNode.params.gain) / 1200;
  const grad = ((nodes.RNGE.audioNode.params.gain - 600) / 1800) * 60;
  const cdist = nodes.RVB.audioNode.params.diffusion * 120;

  const strokeWidth = 0.0045;
  const pow = 1.5;
  const l = 36;

  const rand = mulberry32(graph.seed);
  const h1 = rand() * 360;
  const bg = hsluvToHex([h1 - cdist, 70, 6]);
  const c1 = hsluvToHex([h1, 80, l]);
  const c2 = hsluvToHex([h1 + cdist, 90, l]);
  const c3 = hsluvToHex([h1 + 2 * cdist, 100, Math.min(100, 2 * l)]);

  return (
    <SVG
      key={graph.seed}
      viewBox="0 0 1 1"
      ref={svgRef}
      width={size}
      height={size}
    >
      <rect x="0" y="0" width="1" height="1" fill={bg} />
      <g>
        <g>
          {new Array(count1).fill(undefined).map((_, i) => (
            <circle
              key={i}
              cx={0.5}
              cy={0.5}
              r={Math.pow((i + 1) / count1, pow) * (0.5 + dist) * Math.sqrt(2)}
              stroke={hsluvToHex([h1 + (i / count1) * grad, 80, l])}
              fill="none"
              strokeWidth={strokeWidth}
            >
              <animate
                data-id={`a${i}`}
                attributeName="stroke"
                begin="indefinate"
                dur="0.1s"
                from={c3}
                to={c1}
              />
            </circle>
          ))}
          {playing ? (
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              values={`0 0; ${modAmt} 0; ${-modAmt} 0; 0 0`}
              keyTimes="0; 0.25; 0.75; 1"
              dur={`${1 / mod}s`}
              repeatCount="indefinite"
            />
          ) : (
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              values={`${modAmt / 2} 0; ${modAmt / 2} 0;`}
              keyTimes="0; 1"
              dur="0s"
              repeatCount="1"
            />
          )}
        </g>
        {playing && (
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="translate"
            values={`0 0; 0 ${mod2Amt}; 0 ${-mod2Amt}; 0 0`}
            keyTimes="0; 0.25; 0.75; 1"
            dur={`${1 / mod2}s`}
            repeatCount="indefinite"
          />
        )}
      </g>
      <g>
        <g>
          {new Array(count2).fill(undefined).map((_, i) => (
            <circle
              key={i}
              cx={0.5}
              cy={0.5}
              r={Math.pow((i + 1) / count2, pow) * (0.5 + dist) * Math.sqrt(2)}
              stroke={hsluvToHex([h1 + cdist + (i / count2) * grad, 80, l])}
              fill="none"
              strokeWidth={strokeWidth}
            >
              <animate
                data-id={`b${i}`}
                attributeName="stroke"
                begin="indefinate"
                dur="0.1s"
                from={c3}
                to={c2}
              />
            </circle>
          ))}
          {playing ? (
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              values={`0 0; ${-modAmt} 0; ${modAmt} 0; 0 0`}
              keyTimes="0; 0.25; 0.75; 1"
              dur={`${1 / mod}s`}
              repeatCount="indefinite"
            />
          ) : (
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="translate"
              values={`${-modAmt / 2} 0; ${-modAmt / 2} 0;`}
              keyTimes="0; 1"
              dur="0s"
              repeatCount="1"
            />
          )}
        </g>
        {playing && (
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="translate"
            values={`0 0; 0 ${-mod2Amt}; 0 ${modAmt}; 0 0`}
            keyTimes="0; 0.25; 0.75; 1"
            dur={`${1 / mod2}s`}
            repeatCount="indefinite"
          />
        )}
      </g>
    </SVG>
  );
};
