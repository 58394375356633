import React, { FC, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { FocusLoop } from './FocusLoop';
import { NetworkActivityIndicator } from './NetworkActivityIndicator';
import { ErrorText } from './ErrorText';
import { strokeWidth, lineHeight } from '../style';
import { TAB_ORDER } from '../utils';
import { Shade } from './Shade';
import { Overlay } from './Overlay';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${lineHeight}px;
  font-weight: bold;
`;

const Body = styled.div`
  background-color: var(--menu-color);
  border: solid ${strokeWidth}px var(--menu-border-color);
  padding: ${lineHeight}px;
  position: relative;
  z-index: 2;
  box-sizing: content-box;
`;

const Close = styled.a`
  margin-left: ${lineHeight}px;
  font-size: ${lineHeight}px;
  &:after {
    content: '×';
  }
  &:hover {
    color: var(--outline-secondary-color);
  }
`;

const LoadingErrorContainer = styled.div`
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export type DialogProps = {
  title: string;
  children?: ReactNode;
  close?: () => void;
  width?: number;
  loading?: boolean;
  error?: string | null;
};

export const Dialog: FC<DialogProps> = ({
  title,
  children,
  close,
  width,
  loading,
  error,
}) => {
  const shadeRef = useRef<HTMLDivElement | null>(null);

  return createPortal(
    <Overlay
      onKeyDown={e => {
        e.stopPropagation();
        if (close && e.key === 'Escape') {
          close();
        }
      }}
    >
      <Shade
        ref={shadeRef}
        onClick={e => {
          if (close && e.target === shadeRef.current) close();
        }}
      />
      {loading || error ? (
        <LoadingErrorContainer>
          <NetworkActivityIndicator
            active={true}
            pending={true}
            error={!!error}
          />
          <ErrorText>{error}</ErrorText>
        </LoadingErrorContainer>
      ) : (
        <FocusLoop focusFirst>
          <Body style={{ width }}>
            <Header>
              <span>{title}</span>
              {close && (
                <Close
                  onClick={close}
                  onKeyPress={e => {
                    if (e.key === 'Enter') close();
                  }}
                  tabIndex={TAB_ORDER.DIALOG_CLOSE}
                />
              )}
            </Header>
            {children}
          </Body>
        </FocusLoop>
      )}
    </Overlay>,
    document.body,
  );
};
