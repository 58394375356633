import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { AudioGraphContext } from '../../contexts';
import { useAnimationFrameEffect } from '../../hooks';
import { lineHeight } from '../../style';

export const Outer = styled.div`
  height: ${lineHeight / 3}px;
  border-radius: ${lineHeight / 6}px;
  background-color: var(--grid-color);
  position: relative;
`;

export const Inner = styled.div`
  height: ${lineHeight / 3}px;
  border-radius: ${lineHeight / 6}px;
  background-color: var(--node-color);
`;

export type ProgressAnimationProps = {
  duration: number;
  playing: boolean;
  autoAdvanceStartOffset: number;
};

export const ProgressAnimation: FC<ProgressAnimationProps> = ({
  duration,
  playing,
  autoAdvanceStartOffset,
}) => {
  const { audioGraph } = useContext(AudioGraphContext);
  const [width, setWidth] = useState(0);

  useAnimationFrameEffect(() => {
    const adjustedCurrentTime =
      (audioGraph?.context.currentTime ?? 0) - autoAdvanceStartOffset;
    setWidth(Math.min(1, adjustedCurrentTime / duration / 60));
  }, [audioGraph, duration, autoAdvanceStartOffset]);

  return (
    <Outer>
      {playing && width > 0 && <Inner style={{ width: `${width * 100}%` }} />}
    </Outer>
  );
};
