const frequency: { [c: string]: number } = {
  a: 8.497,
  b: 1.492,
  c: 2.202,
  d: 4.253,
  e: 11.162,
  f: 2.228,
  g: 2.015,
  h: 6.094,
  i: 7.546,
  j: 0.153,
  k: 1.292,
  l: 4.025,
  m: 2.406,
  n: 6.749,
  o: 7.507,
  p: 1.929,
  q: 0.095,
  r: 7.587,
  s: 6.327,
  t: 9.356,
  u: 2.758,
  v: 0.978,
  w: 2.56,
  x: 0.15,
  y: 1.994,
  z: 0.077,
};
const weightedRandomMember = (str: string, rand: () => number) => {
  const letters = str.split('');
  const divisions = letters.reduce(
    (memo, c, i) => ((memo[i] = (memo[i - 1] || 0) + frequency[c]), memo),
    new Array(letters.length),
  );
  return () => {
    const v = rand() * divisions[divisions.length - 1];
    let min = 0;
    let max = divisions.length - 1;
    while (max > min) {
      const mid = Math.floor((max + min) / 2);
      if (divisions[mid] < v) {
        min = mid + 1;
      } else {
        max = mid - 1;
      }
    }
    return letters[min];
  };
};

export const randomName = (
  len: number = Math.floor(6 + Math.random() * 4),
  rand: () => number = Math.random,
) => {
  const randomConsonant = weightedRandomMember('bcdfghjklmnpqrstvwxz', rand);
  const randomVowel = weightedRandomMember('aeiouy', rand);
  let w = new Array(len);
  for (let i = 0; i < len; i++) {
    w[i] = i % 2 === 0 ? randomConsonant() : randomVowel();
  }
  w[0] = w[0].toUpperCase();
  return w.join('');
};
