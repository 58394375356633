import React, { FC } from 'react';
import { FormDialog } from '../../components';

export type EditNameDialog = {
  initialValue: string;
  close: () => void;
  onSubmit: (name: string) => Promise<string | null>;
};

export const EditNameDialog: FC<EditNameDialog> = ({
  initialValue,
  close,
  onSubmit,
}) => {
  return (
    <FormDialog
      title="Edit name"
      buttonText="Save"
      close={close}
      onSubmit={({ name }) => onSubmit(name)}
      fields={{
        name: {
          label: 'Name',
          validate: name => {
            if (name.length < 1) return 'Your playlist needs a name';
            if (name.length > 40) return 'Name is too long';
            return null;
          },
        },
      }}
      initialValues={{
        name: initialValue,
      }}
    />
  );
};
