import React, { FC } from 'react';
import styled from 'styled-components';
import { FormDialog } from '../../components';
import { inputWidth, lineHeight } from '../../style';
import { downloadAudioFile } from '../../audioGraph';
import { Graph } from '../../types';

export type DownloadAudioFileDialogProps = {
  close: () => void;
  graph: Graph;
};

const Row = styled.div`
  margin-top: ${lineHeight}px;
  text-align: center;
  width: ${inputWidth}px;
`;

const floatPattern = /^[0-9]*\.?[0-9]*$/;

export const DownloadAudioFileDialog: FC<DownloadAudioFileDialogProps> = ({
  close,
  graph,
}) => {
  return (
    <FormDialog
      title="Download audio file"
      buttonText="Download"
      close={close}
      onSubmit={async data => {
        const numberOfChannels = 2;
        const sampleRate = 44100;
        const bitDepth = 16;
        const duration = parseFloat(data.duration);
        const fadeIn = 0;
        const fadeOut = Math.min(duration, 2);
        await downloadAudioFile(
          graph,
          numberOfChannels,
          sampleRate,
          bitDepth,
          duration,
          fadeIn,
          fadeOut,
        );
        close();
        return null;
      }}
      fields={{
        duration: {
          label: 'Duration (seconds)',
          pattern: floatPattern,
          validate: value => {
            const d = parseFloat(value);
            return d <= 0
              ? 'Duration must be longer than zero'
              : d > 99999
              ? 'Duration is too long'
              : null;
          },
        },
      }}
      initialValues={{
        duration: '60',
      }}
      renderInfo={values => (
        <Row>
          Wave file, approx.{' '}
          {parseFloat(
            ((2 * 44100 * 16 * parseFloat(values.duration)) / 8000000).toFixed(
              2,
            ),
          ) || 0}
          MB
        </Row>
      )}
    />
  );
};
